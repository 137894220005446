/* Post Registro */
.club-perfil-foto-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 184px;
  width: 184px;
  align-self: center;
}

.club-perfil-foto {
  height: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
  width: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
  border-radius: 50%;
  object-fit: cover;
  border: 8px solid white;
}

.post-register {
  display: flex;
  place-self: center;
  position: relative;
  min-height: 100vh;
  /* width: 100vw; */
  justify-content: center;
  background: #dbdbe9;
}
.post-register-content {
  display: flex;
  place-self: center;
  flex-direction: column;
  background: white;
  justify-content: space-between;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  min-height: 50%;
  /* overflow: scroll; */
  width: 95%;
  padding: 2.5%;
  border-radius: 5px;
}

.post-register-content textarea {
  height: 100px;
  resize: none;
  width: 100%;
}

.club-perfil-edit-foto {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: white;
  transition-duration: 250ms;
}

.club-perfil-edit-foto:hover {
  transform: scale(0.975);
}

.club-perfil-edit-foto input {
  position: absolute;
  height: 40px;
  width: 40px;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
.post-register-content {
  place-self: auto;
}
  .post-register-content textarea {
    height: 100px;
    resize: none;
    width: 100%;
  }
}

.welcome-club-container {
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  justify-content: center;
  align-items: center;
}
.welcome-club-container p {
  width: 62.5%;
  text-align: center;
}
#welcome-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  place-self: center;
}

#logo-welcome {
  position: relative;
  margin-left: 1rem;
  max-height: 110px;
}

@media screen and (max-width: 480px) {
  #welcome-title {
    display: flex;
    flex-direction: column;
  }

  #welcome-title h1 {
    font-size: 2rem;
    margin-right: 1rem;
  }

  #logo-welcome {
    margin-right: 3rem;
    height: 70px;
    margin-top: -15px;
  }

  .welcome-club-container p {
    width: 90%;
    margin-top: 1rem;
    text-align: center;
  }
}
