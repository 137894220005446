#filtros-container {
  background: #f5f6fa;
  display: flex;
  width: 98%;
  flex-direction: column;
  margin-right: 32px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  align-content: center;
  justify-content: center;
  align-items: center;
  background: white;
  padding-bottom: 15px;
}

#filtros-content {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

#filtros-content p {
  color: #d9d9d9;
}

.event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#filtro {
  width: 250px;
}

.tipo-club {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: white;
}

.tipo-club:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Estado del filtro */

.modal-editar {
  position: absolute;
  inset: 50% 50% -45%;
  border: none;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}

#tipo-filtro {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 25px;
  cursor: pointer;
}

#tipo-filtro:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

#dia {
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-weight: bold;
  font-family: revert;
  transition: 0.2s;
}

#dia p {
  margin-top: 3px;
  color: darkslategrey;
}

#header-club {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#column-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px;
  margin-right: auto;
}

#titulo-imagen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  margin-left: 3rem;
}

.logo-club {
  max-width: 100px;
  max-height: 100px;
  border-radius: 18px;
  object-fit: cover;
}

#opciones-club {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: right;
}

#event-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

#icons {
  display: flex;
  flex-direction: row;
  width: 20%;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 10px;
  position: relative;
}
#row-am {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 25px;
  margin-bottom: 20px;
}

#row-pm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 25px;
  margin-bottom: 20px;
}
#horarios {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
}

#agregar-quitar {
  justify-content: space-between;
  margin-right: 3.5rem;
  /* width: 10px;
  height: 10px;
  margin-right: 20px; */
}

#time-chip-disponible {
  width: 65px;
  margin: 10px;
  border: 1px solid black;
  border-radius: 4px;
  text-align: center;
  background-color: #b5e7a0;
}

#time-chip-nodisponible {
  width: 65px;
  margin: 10px;
  border: 1px solid black;
  border-radius: 4px;
  text-align: center;
  background-color: #eca1a6;
}

.main-container-desactivado {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px;
  background-color: #f5f6fa;
  overflow: auto;
  transition-duration: 0.5s;
  width: 100%;
}

.crear-event-container {
  background: #f5f6fa;
  width: 50vw;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin-left: 10vw;
  margin-right: 10vw;
}

.crear-event-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: stretch;
}

.crear-event-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 30px;
  margin-left: 30px;
}

.crear-event-img {
  object-fit: contain;
  position: relative;
  width: 50%;
  margin-right: 30px;
}

.crear-event-img img {
  object-fit: contain;
  position: absolute;
  width: 100%;
  align-content: flex-start;
}

.crear-event-titulo {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.crear-event-label {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}

.crear-event-intext {
  padding: 8px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 2vw;
  width: 350px;
}

.crear-event-input {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  /* width: 400px; */
}

.crear-event-boton {
  position: relative;
  border: none;
  border-radius: 8px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.crear-event-boton:hover {
  background: #000022;
  opacity: 0.6;
}

/* Detalle Evento */

.boton-atras {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.button-back {
  z-index: 1000;
  position: absolute;
  top: 87px;
}

.detalle-content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
  background: white;
  align-items: flex-start;
  justify-content: flex-start;
}

.detail-container {
  background: #f5f6fa;
  display: flex;
  height: 90%;
  flex-direction: column;
  margin-right: 32px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  text-align: left;
  background: white;
  align-items: flex-start;
  justify-content: flex-start;
}

.detail-event-container {
  background: #f5f6fa;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  text-align: left;
  background: white;
  align-items: flex-start;
  justify-content: flex-start;
}

.detalle-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.detalle-portada {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.detalle-alert {
  background-color: #3f2b8e;
  text-align: center;
}

.detalle-alert p {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: white;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 1rem;
}

.foto-evento {
  width: 485px;
  object-fit: contain;
  /* margin-right: 26px;
      margin-bottom: 25px; */
}

.detalle-evento2-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.fechas-container {
  display: flex;
  flex-direction: row;
}

.fecha-container-column {
  display: flex;
  flex-direction: column;
  margin-right: 65px;
}

.detalle-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  text-align: "left";
  overflow: auto;
}

.detalle-organizadores-inscripcion {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.club-right-content {
  width: 70%;
}

/* Moviles */
@media (max-width: 920px) {
  .modal-editar {
    width: 100%;
    height: 100%;
  }
  .main-container-activado {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    /* padding-top: 60px; */
    overflow: hidden;
    transition-duration: 0.5s;
    max-height: calc(100vh - 66px);
    max-height: 100%;
    padding-top: 0;
  }

  .main-container-desactivado {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
    transition-duration: 0.5s;
    /* margin-left: 15px; */
  }
  .detail-event-container {
    background: #f5f6fa;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    text-align: left;
    background: white;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .detail-container {
    background: #f5f6fa;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    margin-right: 32px;
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    text-align: left;
    background: white;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
