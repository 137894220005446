.sponsor-img-container {
    width: 100%;
    height: 50%;
    position: sticky;
    /* max-height: 45vh; */
}

.sponsor-img {
    position: relative;
    width: 100%;
    height: 60vh;
    object-fit: cover;
}

.Register-sponsor-title {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 100%;
    align-items: center;
}

.register-form-sponsor{
    display: grid;
    grid-template-rows: auto max-content;
    height: 100%;
    width: 100%
}

.register-form-sponsor h3{
    font-family: "Bitner Heavy Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    font-weight: bold;
    font-size: calc(20px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
}



.register-form-content-sponsor{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    padding: 50px;
    /* align-items: center; */
    align-self: center;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    justify-self: center;
}

.sponsor-info{
    /* display: flex;
    flex: 1 1;
    padding-left: 8vw;
    padding-right: 8vw;
    /* width: 100%;
    height: 50%; */ 

    display: flex;
    flex: 1 1;
    padding-left: 8vw;
    padding-right: 8vw;
    margin-top: 1rem;
}

.sponsor-screenshots-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 32px;
    padding-bottom: 32px;
}

.login-text-container {

    flex: 1 1;
    padding-right: 4vw;
    flex-direction: column;
    justify-content: center;
}

.sponso-text {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
    /* margin: -15px; */
}

.login-text-container p {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #6F767A;
    margin-top: 32px;
}

.help-content-land-sponsor {
    display: flex;
    flex-direction: row;
    /* padding-left: 18vw;
    padding-right: 8vw;
    padding-top: 8vw; */
    padding: 2vw;
}
.help-text-container-sponsor {
    display: flex;
    flex:1;
    padding-left: 4vw;
    flex-direction: column;
    justify-content: center;
}

.help-text-container-sponsor p {
    font-family: "Objet Bold Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    font-size: 18px;
    text-align: right;
    color: #6F767A;
    letter-spacing: 0;
    margin-top: 32px;
}

.help-text-container-sponsor h1 {
    font-family: "Bitner Heavy Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    text-align: right;

    color: #333;
    letter-spacing: 0;
}


  .help-screenshots-container-sponsor {
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 32px;
    padding-bottom: 32px;
  }

  .conf-screenshots {
    width: 300px;
    object-fit: contain;
}


.sponsor-intext-area{
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 15px 10px;
    border-radius: 8px;
    border-style: solid;
    border-color: #efeff7;
    font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    height: 46%;
    width: 100%;
    margin-left: 0;
}

.form-court-event-intext-area
{
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 10px;
    display: flex;
    border: 1.5px solid #202020;
    border-radius: 4px;
    font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    width: 100%;
    margin-left: 0;
}


.form-court-event-intext-area:focus-visible,
.form-court-event-intext-area:focus{
  border: 1.5px solid #202020;
  outline: none;
}


/* .sponsor-info-content{
    display: flex;
    flex: 1 1;
    flex-direction: row;
} */



/* .sponsor-info-container{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    padding-top: 4vw;
}


.sponsor-info h2{
    text-align: center;
    padding-top: 1rem;
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(25px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
} */


@media screen and (max-width: 920px) {
    .register-form-content-sponsor{
        width: 100%;
    }

    .sponsor-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sponsor-screenshots-container{
        margin-right: 0px;
    }

    .help-content-land-sponsor {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .help-text-container-sponsor {
        display: flex;
        flex: 1 1;
        padding-left: 4vw;
        flex-direction: column;
    }
    .help-text-container-sponsor h1 {
        font-family: "Bitner Heavy Italic";
        font-weight: bold;
        text-align: center;
        color: #333;
        /* margin: -15px; */
        margin-right: 15px;
    }

    .help-text-container-sponsor p {
        font-family: "Objet Bold Italic";
        text-align: center;
        color: #6F767A;
        letter-spacing: 0;
        font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
        margin-top: 32px;
        margin-right: 15px;
    }

    .sponsor-img {
        height: 40vh;
    }

}