.shadow-lg {
  border-radius: 8px;
  width: 50vw;
  background-color: #fff;
  height: 95%;
  transition: height 400ms;
}

.info-container {
  /* padding-left: 4vw; */
  width: 95%;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 45%;
  align-items: center;
  margin-left: 2%;
  margin-bottom: 20px;
  /* padding-bottom: 10%; */
}

.info-container-paid-success{
  /* padding-left: 4vw; */
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 87%;
  align-items: center;
}

.info-container-paid-failed{
  /* padding-left: 4vw; */
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
}


.info-content {
  display: flex;
  width: 350px;
  flex: 1;
  padding-top: 2vw;
  padding-left: 2vw;
  flex-direction: column;
  /* background-color: #dedee9; */
  border:3px solid #efeff7;
  border-radius: 5px;
  
}

.info-content h2 {
  font-size: 28px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
}


.info-content-paid {
  display: flex;
  flex-direction: column;
  height: 50%;
}

.info-transaction {
  display: flex;
  justify-content: center;
  height: 13%;
  
}

.info-transaction h2 {
  margin-top: 20px;
  /* margin-bottom: 20px; */
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
}

.sucess-paid{
  max-width: 195px;
  max-height: 185px;
  border-radius: 18px;
  object-fit: cover;
}

.info-paid-tittle-failed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45%;
}
.info-paid-tittle h2 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
}

.info-paid-tittle-failed h2 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 60px;
  margin-left: 60px;
}

.info-paid{
  margin-bottom: 1rem;
  font-size: 1rem;
}

.btn-paid-success {
  height: 40px;
  border: none;
  border-radius: 8px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
  margin-top: 15px;
}

.btn-paid-failed {
  height: 40px;
  border: none;
  border-radius: 8px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
}

.btn-content-success {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 20%;
  width: 55%;
}

.btn-content-failed {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 15%;
  width: 55%;
}

.info-container-paid a {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-self: center;
  color: black;
}

.info-content h4 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
}
.info-content h5 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 40px;
  color: #949494;
  text-align: left;
}
.info-content h6 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 10px;
  color: #949494;
  margin-bottom: 1px;
  text-align: left;
}
.info-content p {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.info-user {
  display: grid;
  grid-template-columns: min-content auto max-content;
  /* margin-bottom: 55px; */
  flex-direction: column;
  margin: 30px 20px;
  align-items: center;
}

.info-user h3 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: 18px;
  text-align: right;
  margin-bottom: 1px;
}
.info-user p {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 10px;
  color: #949494;
  text-align: right;
}

.trofeo-img {
  width: 120px;
  object-fit: contain;
}

.logo-img {
  width: 73px;
  object-fit: contain;
}

.logo-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.logo-end{
  width: 100%;
  justify-content: center;
  display: flex;
  height: 8%;
  padding-bottom: 2%;
}

.logo-end-failed{
  width: 100%;
  justify-content: center;
  display: flex;
  height: 45%;
  max-height: 10%;
  padding-bottom: 2%;
}

.logo-end-success {
  width: 100%;
  justify-content: center;
  display: flex;
  height: 10%;
  padding-bottom: 2%;
}

.container-info-event {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-profile {
  display: flex;
  flex-direction: column;
}

.perfil-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  align-self: flex-end;
  border-radius: 50%;
}

.shadow-lg h1 {
  /* margin-left: 60px; */
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 35px;
  margin: 0;
  margin-left: 12px;
}

.pago-container {
  display: flex;
  flex: 1;
  height: 25%;
  flex-direction: row;
  justify-content: center;
}

.container-button{
  position: relative;
  bottom: 200px;
}



/* Moviles */
@media (max-width: 920px) {
  .shadow-lg {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    /* background-color: lightseagreen; */
  }

  .shadow-lg h1 {
    margin-left: 20px;
    margin-top: 60px;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 28px;
    text-align: left;
  }

  .info-container {
    /* width: 320px; */
    display: flex;
    flex: 1;
    /* padding-left: 6vw; */
    /* flex-direction: column; */
  }

  .info-user {
    display: flex;
    flex: 1;
    padding-top: 2vw;
    align-self: center;
    /* padding-left: 2vw; */
    padding-bottom: 4vw;
    flex-direction: column;
    justify-items: center;
    text-align: justify;
    margin-bottom: 0;
    width: 100%;
  }

  .pago-container {
    display: flex;
    flex: 1;
    /* flex-direction: column; */
    justify-content: center;
  }

  .trofeo-img {
    width: 80px;
    object-fit: contain;
  }

  .shadow {
    margin-bottom: 15px;
    height: 107px;
  }

  .perfil-img{
    margin-right: 20px;
  }
  .info-user h3{
    font-size: 14px;
  }

  .info-user p{
    margin-right: 20px;
  }

  .btn-paid-success{
    height: 64px;
    font-size: calc(15px + 4 * ((100vw - 300px) / (1600 - 300)));
    width: 130%;
  }

  .btn-paid-failed{
    height: 64px;
    font-size: calc(15px + 4 * ((100vw - 300px) / (1600 - 300)));
    width: 130%;
  }

  .info-container-paid-failed{
    height: 85%;
  }

  .logo-end-success {
    height: 6%;
    /* padding-bottom: 1%; */
  }
  

}




@media (max-width: 420px) {
  .pago-container {
    display: flex;
    flex: 1;
    height: 20rem;
    justify-content: center;
    flex-direction: column;
  }
}




/* Tabletas */

@media (min-width: 480px) and (max-width: 900px) {
  .shadow-lg {
    border-radius: 8px;
    width: 100%;
    height: 100vh;
    /* background-color: lightseagreen; */
  }
  .pago-container {
    display: flex;
    flex: 1;
    /* flex-direction: column; */
    justify-content: center;
  }
  .shadow {
    margin-bottom: 15px;
  }
}
