.new-court-content {
  width: 100%;
  height: 100%;
  text-align: -webkit-center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.container-form-columns-court {
  display: flex;
  flex-direction: column;
  /* margin-top: 8%; */
}

.container-form-columns-court-welcome {
  display: flex;
  flex-direction: column;
}

.court-event-label {
  /* font-family: "Objet Regular Italic"; */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0rem;
  width: 100%;
  padding-bottom: 0px;
  text-align: center;
  text-align: start;
}
.form-column-court {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 20px;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}
.add-court-input{
  min-height: 105px;
}
.add-court-input,
.add-court-textarea {
  font-family: "Objet Regular Italic";
  flex: 1 1;
  width: 100%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: flex-start;
}
.add-court-textarea {
  grid-column: span 2;
}

.court-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
}
#container-new-court-calendar {
  background: white;
  display: flex;
  /* width: 100%; */
  height: 100%;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  align-content: center;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 2rem;
  overflow-x: auto;
}
#filtros-content-court {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.court-event-intext,
.court-event-intext-area,
.select-court-event-intext 
  {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 10px;
    display: flex;
    border: 1.5px solid #202020;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
    margin-left: 0;
}
.court-event-intext:focus-visible,
.court-event-intext-area:focus-visible,
.select-court-event-intext:focus-visible,
.court-event-intext:focus,
.court-event-intext-area:focus,
.select-court-event-intext:focus{
  border: 2px solid #a0cf1f;
  outline: none;
}
.court-event-intext {
  height: fit-content;
}
.court-event-intext-area {
  height: 100%;
}
.select-court-event-intext {
  height: fit-content;
}
.court-club-error {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 15px 10px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  margin-left: 0;
  resize: none;
  border: 1px solid red;
}

.court-club-errorMessage {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: red;
  font-size: calc(6px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
}



.column-img-court {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  background-color: #dbdbe9;
  grid-row: span 3;
}

.column-img-court img {
  width: 100%;
  border-radius: 0;
  object-fit: cover;
  aspect-ratio: 1;
}

#new-court-photo {
  place-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: white;
}
#new-court-photo input {
  position: absolute;
  height: 125px;
  width: 125px;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}
#file-upload-button{
  width: 100%;
  height: 100%;
  cursor: pointer;
}
#new-court-photo-added {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 20%);
  background-color: white;
  transition-duration: 250ms;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}

#new-court-photo-added input {
  position: absolute;
  height: 40px;
  width: 40px;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}

.icon-edit-c {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: -48px;
  margin-right: 16px;
  transition-duration: 250ms;
}

.img-court {
  width: 100%;
  height: 100%;
  background-color: white;
}

@media screen and (max-width: 850px) {
  .form-column-court {
    flex-direction: column;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
  .add-court-textarea {
    grid-column: span 2;
  }
  .column-img-court {
    grid-row: span;
    grid-row: span 2;
  }
}
@media screen and (max-width: 480px) {
  .column-img-court {
    grid-row: span;
    grid-column: span 2;
  }

  .court-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }
  .container-form-columns-court {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  #container-new-court-calendar {
    background: white;
    display: flex;
    /* width: 100%; */
    height: 100%;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    align-content: center;
    align-items: center;
    padding-bottom: 0px;
    margin-bottom: 1rem;
    margin-left: 0rem;
    margin-right: 0rem;
    overflow-x: auto;
  }

  .select-court-event-intext {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 15px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin-left: 0;
  }

  .court-event-intext {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 15px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    height: fit-content;
    width: 100%;
    margin-left: 0;
  }
  .court-event-label {
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
  .court-event-intext-area {
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
  .court-club-errorMessage {
    font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
  .court-club-error {
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
  .rent {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
  }
}
