* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .recover-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 55%;
    justify-content: space-between;
    border: solid 1px rgb(202, 202, 202);
    border-radius: 1rem;
    padding: 30px;
    background-color: white;
    margin-bottom: 80px;
    box-shadow: 1px 5px 15px 5px #000000;
  }

  .card-content h1 {
    margin-top: 10px;
    font-family: 'Airbnb Cereal App',Arial,Helvetica,sans-serif;
    font-weight: bold;
    font-size: 32px;
  }

  .card-content h2 {
    font-family: 'Airbnb Cereal App',Arial,Helvetica,sans-serif;
    font-weight: normal;
    font-size: 20px;
  }

  .card-content input {
    width: 100%;
  }

  .card-content button {
    margin-bottom: 10px;
  }

  .tittle-card {
      display: flex;
      font-size: 24px;
      font-weight: bold;
      color: white;
      font-weight: normal;
      font-family: 'Airbnb Cereal App',Arial,Helvetica,sans-serif;
      margin-left: 1rem;
      margin-top: 1rem;
  }


  .recover-intext{
      padding: 8px 0px 8px 10px;
      border-radius: 8px;
      width: 80%;
      font-family: 'Airbnb Cereal App',Arial,Helvetica,sans-serif;
      border-style: solid;
      border-color: #97979e;
  }

  .recover-button {
    width: 45%;
    height: 50px;
    border: none;
    border-radius: 25px;
    background-color: black;
    color: #fff;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    margin-bottom: 20px;
    align-self: center;
  }

  .recover-button:hover {
    transform: scale(0.98);
    box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
    background-color: rgb(75, 74, 74);
  }

  .MuiCardActions-root {
    justify-content: center;
  }

  .card {
    height: 80px;
     background-color: rgb(48, 185, 82);
  }

  .logo-tenis {
    margin-left: 25px;
    width: 120px;
    object-fit: contain;
    margin-right: 26px;
    margin-bottom: 25px;
  }

  .restore-password-input{
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    display: flex;
    width: 60%;
    flex-direction: column;
    font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
    align-items: center;
    padding-bottom: 20px;
  }

  .restore-password-intext{
    display: inline-flex;
    justify-content: space-between;
    width: 80%;
    height: 50px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px;
    font-family: "Airbnb Cereal App",Arial,Helvetica,sans-serif;
    border-style: solid;
    border-color: #efeff7;
    border-width: 2px;
  }

  /* Sweet Alert */

 .swal-button-container {
   width: 100%;
   text-align: center;
   margin: 5px 0px 5px 0px;
 }

 

  @media screen and (max-width: 480px){

    .card-content {
      display: flex;
      flex-direction: column;
      width: 80%;
      height: 60%;
      justify-content: space-between;
      border: solid 1px;
      border-radius: 1rem;
      padding: 30px;
      background-color: white;
      margin-bottom: 40px;
    }

    .card-content h1 {
      text-align: center;
      font-size: 26px;
    }

    .card-content h2 {
      text-align: center;
      font-size: 15px;
    }

    .card-content input {
      width: 90%;
      align-self: center;
    }

    .recover-button {
      width: 95%;
      height: 45px;
      border: none;
      border-radius: 5px;
      background-color: black;
      color: #fff;
      font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: bold;
      transition: all 0.2s ease-in-out;
      align-self: center;
    }

    .recover-intext {
      font-size: 14px;
    }

    .logo-tenis {
      margin-left: 25px;
      width: 100px;
      object-fit: contain;
      margin-right: 26px;
      margin-bottom: 25px;
    }
    

    .restore-password-input{
      width: 100%;
      font-size: calc(13px + 4 * ((100vw - 300px) / (1600 - 300)));
      padding-bottom: 40px;
    }

    .restore-password-intext {
      width: 95%;
    }
    @media (max-height: 568px) and (max-width: 320px) {
      .restore-password-input{
        width: 100%;
        font-size: calc(13px + 4 * ((100vw - 300px) / (1600 - 300)));
      }
    }


  }