.calendar-no-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.circleItemHour,
.itemHour,
.itemHourNd,
.itemHourBase {
  border: 1px double #2b0ae71f;
  border-bottom: none;
  min-height: 25px;
  min-width: 25px;
}
.disponible {
  border-top: 1px double rgba(231, 231, 231, 0.445);
  border-bottom: none;
  background-color: #1275a5;
}
.nodisponible {
  background-color: #efefef;
}
/* .disponible{
  background-color: #0feb75ad;
} */
.courtocupada {
  background-color: #d44517;
}
.ds-selected.disponible {
  border-top: 1px double #048fdf;
  border-bottom: none;
  outline-offset: 1px;
  color: black;
  font-weight: bold;
  background: #10a9f5;
}
.ds-selected {
  outline-offset: 0.5px;
  color: black;
  font-weight: bold;
  background: #00ff55b0;
}

.itemHourBase {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 50px;
  font-size: 12px;
  font-weight: 800;
  color:rgb(117, 117, 117);
  justify-content: center;
  position: relative;
}
.itemHourBaseMSelected,
.itemHourBaseMMSelected {
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: #00fa534d;
  top: 0;
}
.itemHourBaseMMSelected {
  top: 50%;
}
.itemHourBaseSelected {
  background-color: #00ff55b0;
}
.CalendarHours {
  display: grid;
  grid-template-columns: repeat(8, 12.5%);
  overflow-y: auto;
  margin: 0 20px;
  padding-inline: 20px;
}
.New-CalendarHours {
  display: grid;
  grid-template-columns: repeat(7, 10.5%);
  overflow-y: auto;
  padding-inline: 4px;
  justify-content: center;
}
.CalendarHours-Description {
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .itemHour,
  .itemHourNd {
    min-height: 40px;
  }
  .itemHourBase {
    min-height: 80px;
  }
}
@media screen and (max-width: 600px) {
  .CalendarHours {
    margin: 0;
    padding-inline: 0;
    overflow: visible;
  }
  .New-CalendarHours {
    display: grid;
    margin: 0;
    padding-inline: 0;
    overflow: visible;
    justify-content: space-evenly;
  }
}
.hora {
  border-bottom: 0px solid #cacaca;
}
.horaMedia {
  border-top: 0px solid #cacaca;
}

/* Tamaño del scroll */
.Scroll::-webkit-scrollbar,
.ReactModal__Overlay--after-open::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra (thumb) de scroll */
.Scroll::-webkit-scrollbar-thumb,
.ReactModal__Overlay--after-open::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.Scroll::-webkit-scrollbar-thumb:active,
.ReactModal__Overlay--after-open::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.Scroll::-webkit-scrollbar-thumb:hover,
.ReactModal__Overlay--after-open::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
.Scroll::-webkit-scrollbar-track,
.ReactModal__Overlay--after-open::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
}

.Scroll::-webkit-scrollbar-track:hover,
.ReactModal__Overlay--after-open::-webkit-scrollbar-track:hover,
.Scroll::-webkit-scrollbar-track:active,
.ReactModal__Overlay--after-open::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
