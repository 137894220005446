.dashboard-container {
    background: #F5F6FA;;
    display: flex;
    flex-direction: row;

}
.dashboard-main-container{
    user-select: none;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: max-content auto;
}

/* Menu */
.menu-action-activado {
    /* Da la propiedad de quedarse fijo, sin moverse del menu */
    display: flex;
    /* -- */
    margin-left: 15px;
    transition-duration: 250ms;
    cursor: pointer;
    font-size: 36px;
}

.menu-action-activado:hover {
    transition: 0.4s;
    transform: scale(0.95);
}

.menu-action-desactivado {
/* Da la propiedad de quedarse fijo, sin moverse del menu */
    display: flex;
    margin-left: 15px;
    transition-duration: 250ms;
    cursor: pointer;
    font-size: 36px;
}

.menu-action-desactivado:hover {
    transition: 0.4s;
    transform: scale(0.95);
}
.main-container-activado {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px;
    width: calc(100% - 64px);
    background-color: #f5f6fa;
    overflow: scroll;
    transition-duration: 0.5s;
    width: 100%;
    overflow: auto;
    max-height: calc(100vh - 66px);
    max-height: 100%;
  }


  .main-container-activado-3 {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px;
    width: calc(100% - 64px);
    background-color: #f5f6fa;
    overflow: scroll;
    transition-duration: 0.5s;
    width: 100%;
    overflow: auto;
    max-height: calc(85vh - 66px);
  }

.main-container-activado-2 {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px;
    width: calc(100% - 64px);
    background-color: #f5f6fa;
    overflow: scroll;
    transition-duration: 0.5s;
    width: 100%;
    overflow: auto;
    height: 100%;
  }
.menu-container-activado {
    display: flex;
    flex-direction: column;
    /* position: fixed; */
    width: 237px;
    height: 100vh;
    background: #FEFEFE;
    justify-content: space-between;
    border-right: 2px solid #EEF1FB;
    box-shadow: 9px 4px 17px rgb(156 156 203 / 10%);
    transition-duration: 0.5s;
    overflow: auto;

}


.menu-container-desactivado {
    display: flex;
    flex-direction: column;
    /* position: fixed; */
    width: 237px;
    margin-left: -237px;
    background: #FEFEFE;
    justify-content: space-between;
    border-right: 2px solid #EEF1FB;
    box-shadow: 9px 4px 17px rgba(156, 156, 203, 0.1);
    transition-duration: 0.5s;
    height: 100vh;
    overflow: auto;

}

.menu-container-activado p {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #9191A8;
}

.menu-container-desactivado p {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #9191A8;
}

.menu-logo {
    width: 80px;
    object-fit: contain;
}

.menu-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
}


.action-container {
    padding-top: 18px;
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    transition: background-color .3s;
    background-color: white;
}
.action-container-selected {
    background-color: #e2ecc5;
}
.action-container:hover {
    background-color: rgba(0,0,0,0.02);
} 
.action-container-selected:hover {
    background-color: #eafab9;
}
.action-cerrar {
    display: flex;
    flex-direction: row;
    /* padding-top: 18px; */

}

.action-cerrar:hover {
    background-color: #F5F6FA;
    cursor: pointer;
    transition: 0.28s;
}

.action-icon {
    width: 50px;
    margin-left: 15px;
    color: #BEBECF;
}
/* Menu */

/* User Bar */
.user-container {
    top: 0px;
    display: flex;
    width: 100%;
    height: 66px;
    background: rgb(254, 254, 254);
    justify-content: space-between;
    align-items: center;
}

.user-img{
    width: 36px;
    height: 36px;
    border-radius: 100%;
    object-fit: contain;
    cursor: pointer;
    object-fit: cover;
}

.user-img-perfil-club{
    width: 36px;
    height: 36px;
    border-radius: 18px;
    object-fit: contain;
    margin-right: 70px;
    margin-bottom: 25px;
    cursor: pointer;
    object-fit: cover;
}

.user-icons{
    object-fit: contain;
    margin-right: 15px;
    margin-top: 7px;
}

.user-content {
    margin-right:15px ;
    display: flex;
    flex-direction: row-reverse;
}
/* User Bar */

/* Eventos */
.content-container {
    background: #F5F6FA;
    padding-top: 67px;
    padding-left: 237px;
    padding-right: 10px;
    height: 1000px;
    position: static;
    display: flex;
    flex-direction: column;
}

.acciones {
    margin-top: 56px;
    margin-left: 55px;
    margin-bottom: 35px;
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
}

.crear-evento{
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    padding-top: 16px;
    padding-bottom: 16px;
    
    width: 240px;
    margin-left: 55px;
    box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.25);
    text-align: center;
    background: #FFFFFF;
    align-items: center;
    justify-content: center;
}

.event-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px;
    margin-left: -15px;
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
}

.arrow-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.arrow-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.evento {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    width: 238px;
    height: 238px;
    background: #FFFFFF;
}

.crear-evento h6{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    margin: 0
}

.icono{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EEF9FF;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    margin-right: 18px;
}
/* Eventos */
    
.load {
    margin-bottom: 50%;
}


.pointer {
    padding: 9px;
    cursor: pointer; 
}

.scroll-menu-arrow {
    padding: 10px;
    cursor: pointer;

}
  
  .scroll-menu-arrow--disabled {
    visibility: hidden;
    display: flex;
 
    
}

/* Estadisticas */

.estadisticas-container-activado {
    background: #F5F6FA;
    padding-top: 67px;
    padding-left: 237px;
    padding-right: 10px;
    height: 1500px;
    position: static;
    display: flex;
    flex-direction: column;
    transition-duration: 0.5s;
}

.estadisticas-container-desactivado {
    background: #F5F6FA;
    padding-top: 67px;
    padding-right: 10px;
    height: 1500px;
    position: static;
    display: flex;
    flex-direction: column;
    transition-duration: 0.5s;

}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.torneos-disputados {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    width: 90%;
    height: 113px;
    margin-left: 55px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    background: rgba(46, 123, 127, 0.9);;
    align-items: center;
    justify-content: space-between;
    transition-delay: 0.5s
}


.torneos-disputados h1{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: white;
    text-align: left;
    margin-top: 25px;
    margin-left: 10px;
    transition-delay: 0.5s

}

.torneos-disputados p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    /* font-weight: bold; */
    font-size: 18px;
    color: white;
    text-align: left;
    margin-left: 10px;
    transition-delay: 0.5s

}


.torneos-disputados h2{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: white;
    text-align: center;
    margin-right: 20px;
}

.torneos-disputados p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    /* font-weight: bold; */
    font-size: 14px;
    color: white;
    text-align: left;
}

.torneos-participantes {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    width: 90%;
    height: 113px;
    margin-left: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    background: #459DD6;
    align-items: center;
    justify-content: space-between;
}

.torneos-participantes h1{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: white;
    text-align: center;
    margin-top: 25px;
    margin-left: 10px;
}

.torneos-participantes h2{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: white;
    text-align: center;
    margin-right: 20px;
}


.torneos-participantes p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    /* font-weight: bold; */
    font-size: 14px;
    color: white;
    text-align: left;
    margin-left: 12px;
}


.torneos-visitas {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    width: 90%;
    height: 113px;
    margin-left: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    background: #C96B43;
    align-items: center;
    justify-content: space-between;
}

.torneos-visitas h1{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: white;
    text-align: left;
    margin-top: 25px;
    margin-left: 12px;

}

.torneos-visitas h2{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: white;
    text-align: center;
    margin-right: 20px;
}


.torneos-visitas p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    /* font-weight: bold; */
    font-size: 14px;
    color: white;
    text-align: left;
    margin-left: 12px;
}



.tarjetas-container {
    padding-right: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.graficos-actividad-container{
    margin-left: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.graficos-scope-container{
    margin-top: 2rem;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.graficos {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    width: 90%;
    height: 510px;
    margin-top: 20px;
    margin-left: 20px;
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    background: white;
    align-items: flex-start;
    justify-content: flex-start;
}

.scope{
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    width: 92%;
    height: 45%;
    margin-top: 20px;
    margin-left: 55px;
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    background: white;
    align-items: flex-start;
    justify-content: flex-start;
}

.actividad {
    background-color: cadetblue;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    height: 510px;
    width: 50%;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    background: white;
    align-items: flex-start;
    justify-content: space-between;
    justify-items: right;
}

.actividad-icon {
    margin-left: 90px;
}

.graficos-actividad-container h1 {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    width: 100%;
    font-size: 24px;
    padding: 15px;
    color: black;
    text-align: left;
    border-bottom: 1px solid #C4C4C4;
}

.nombreevento {
    width: 206px;
    height: 19px;
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-top:12px;
    margin-left: 16px;
    margin-right: 64px;
    color: #000022;
}

.tipo{
    position: absolute;
    width: 206px;
    height: 19px;
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
    color: #949494;
}

.fechain {
    position: absolute;
    width: 206px;
    height: 19px;
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-top: 17px;
    color: #000022;
}

.imagen{
    position: absolute;
    width: 238px;
    height: 131px;
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 106px;
    margin-top: 1px;
}

.toaster-notification {
    display: flex;
    gap: 10px;
}

.toaster-notification span {
    margin-top: 2px;
}

.toaster-notification button {
    border: none;
    padding: 2px 8px 2px 8px;
    border-radius: 8px;
    font-weight: 500;
}

@media (max-width: 600px) {
    
    .menu-action-activado { 
        font-size: 24px;
    }

    .menu-action-desactivado {
        font-size: 24px;
    }
    
    .tarjetas-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .torneos-disputados {
        display: flex;
        flex: 0;
        flex-direction: row;
        border-radius: 4px;
        width: 85%;
        height: 90px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: center;
        background: rgba(46, 123, 127, 0.9);;
        align-items: center;
        justify-content: space-between;
        transition-delay: 0.5s;
    }
    
    .torneos-participantes {
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        width: 85%;
        height: 90px;
        margin-left: 52px;
        margin-top: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: center;
        background: #459DD6;
        align-items: center;
        justify-content: space-between;
    }
    .torneos-visitas {
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        width: 85%;
        margin-top: 15px;
        height: 90px;
        margin-left: 52px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: center;
        background: #C96B43;
        align-items: center;
        justify-content: space-between;
    }
    
    .graficos-actividad-container{
        margin-left: 35px;
        width: 90%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
    }
    
    .graficos {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        width: 90%;
        height: 510px;
        margin-top: 20px;
        margin-left: 20px;
        box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: center;
        background: white;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .actividad {
        background-color: cadetblue;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        height: 510px;
        width: 90%;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: center;
        background: white;
        align-items: flex-start;
        justify-content: space-between;
        justify-items: right;
    }
    
}
@media (max-width: 500px) {
    .menu-container-activado{
        width: 100%;
    }
}