* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.root {
  border: none;
  border-radius: 25px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container-form {
  display: flex;
  flex-direction: column;
}
.torneo-edit-foto input {
  height: 40px;
  width: 40px;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}
.torneo-event-general {
  grid-column: span 2;
}
.torneo-event-preferencias {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 65px;
  grid-column: span 3;
}
.torneo-event-general-information {
  display: grid;
  grid-template-columns: 40% 60%;
  max-height: 198px;
  width: 100%;
  /* max-height: 309px; */
  text-align: start;
  margin-top: 10px;
}
.torneo-event-photo {
  max-height: 285px;
  object-fit: contain;
  border: 0.1px solid black;
  margin-top: 0;
  aspect-ratio: 1;
  width: 100%;
}
.torneo-edit-foto {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: white;
  transition-duration: 250ms;
  bottom: 5px;
  right: 5px;
}
.torneo-edit-foto-icon {
  position: absolute;
  pointer-events: none;
}
.torneo-event-edit-img {
  flex-direction: column;
  align-items: center;
  position: relative;
  grid-row: span 3;
  display: flex;
  justify-content: center;
}
.torneo-edit-foto:hover {
  transform: scale(0.975);
}

#modalLindo {
  overflow: auto;
}

#modalLindo::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

#modalLindo::-webkit-scrollbar-thumb {
  background-color: "rgba(0,0,0,0.75)";
  border-radius: 4px;
}

#modalLindo::-webkit-scrollbar-thumb:hover {
  background: #494846;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

#modalLindo::-webkit-scrollbar-thumb:active {
  background-color: #494846;
}

#modalLindo::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.torneo-event-content {
  padding-right: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 65px;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 64px 74px;
  padding-inline: max(74px, calc((100% - 1024px) / 2));
}

.torneo-event-information {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.event-general-information {
  display: flex;
  width: 108%;
  height: 25%;
  text-align: start;
  margin-top: 10px;
}

#event-img {
  width: 40%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.event-photo {
  width: 85%;
  height: 85%;
  margin: 0px 32.5px 20px 20px;
  object-fit: cover;
  border: 0.1px solid black;
}

.torneo-event-general-information-inputs {
  width: 100%;
  align-self: center;
}
.torneo-event-general-information-inputs h3 {
  align-self: flex-start;
  margin-left: 0;
  font-size: 20px;
  font-weight: bold;
}
.general-information-inputs {
  width: 70%;
  align-self: center;
}
.general-information-inputs h3 {
  align-self: flex-start;
  margin-left: 0;
  font-size: 20px;
  font-weight: bold;
}
.event-preferences {
  display: flex;
  flex-direction: column;
  height: 15%;
  width: 102.5%;
}

.event-preferences h3 {
  align-self: flex-start;
  margin-left: 2.5%;
  font-size: 20px;
  font-weight: bold;
}

.preferences-inputs {
  width: 95%;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.event-location {
  display: flex;
  flex-direction: column;
  height: 15%;
  width: 105%;
}

.event-location h3 {
  align-self: flex-start;
  margin-left: 2.5%;
  font-size: 20px;
  font-weight: bold;
}

.location-information {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 95%;
  height: 100%;
}

.map-location-event {
  display: flex;
  flex-direction: column;
  width: 105%;
  height: 20%;
}

.map-location-event h3 {
  align-self: flex-start;
  margin-left: 2.5%;
  font-size: 20px;
  font-weight: bold;
}

.map-information-event {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 95%;
  height: 70%;
}

.container-map {
  height: 35%;
  width: 95%;
  align-self: center;
}

.torneo-event-form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: auto;
}

.torneo-form-container {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}

.torneo-modal1-header {
  width: 500px;
  height: 500px;
}

.torneo-event-titulo {
  display: flex;
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: calc(16px + 4 * ((100vw - 300px) / (1600 - 300)));
  margin-left: 2.5%;
  width: 100%;
}

.torneo-event-label {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0rem;
  width: 100%;
  padding-bottom: 0px;
  text-align: center;
  text-align: start;
}
.torneo-event-button {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 10px;
  display: flex;
  border: 1.5px solid #242424;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  margin-left: 0;
}

.torneo-event-intext {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 8px 10px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  margin-bottom: 1vw;
  width: 90%;
  margin-left: 0;
}

.torneo-event-intext-region {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 8px 10px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  margin-bottom: 1vw;
  width: 90%;
  margin-left: 0;
  height: 45%;
}

.torneo-event-intext-location {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 8px 10px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  margin-bottom: 1vw;
  width: 90%;
  margin-left: 0;
}

.torneo-event-input-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 45%;
  align-items: center;
}

.container-image-creartorneo {
  background-image: url("./../assets/crear-evento3.jpg");
  background-size: cover;
  background-position: center;
  /* border-radius: 25px 0 0 25px; */
  width: 40%;
  height: 100%;
}

.torneo-event-input {
  min-height: 91px;
  font-family: "Objet Regular Italic";
  width: 100%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: flex-start;
  display: flex;
}

.torneo-errorMessage {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: red;
  font-size: calc(6px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
}

.torneo-error {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 8px 10px;
  display: flex;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  height: 40%;
  width: 90%;
  margin-left: 0;
}

.torneo-crear-event-boton {
  /* display: flex; */
  position: relative;
  border: none;
  border-radius: 8px;
  background-color: #000022;
  color: #002688;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
}
.torneo-cancelar-event-boton {
  /* display: flex; */
  position: relative;
  border: none;
  border-radius: 8px;
  background-color: #f2f2f2;
  color: #002688;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  /* padding: 4px 10px 4px 10px; */
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
}

.torneo-event-boton:hover {
  background: #000022;
  opacity: 0.6;
}

.torneo-btn_form {
  /* background: red; */
  justify-content: space-around;
  height: 60px;
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 5px;
  grid-gap: 10px 20px;
  grid-column: span 3;
}

.torneo-btn_form_tennis {
  /* background: red; */
  justify-content: space-around;
  height: 60px;
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-bottom: 5px;
  grid-gap: 10px 20px;
  grid-column: span 3;
}
.torneo-btn_form-no-grid-colum {
  grid-column: span 1;
}
/* Modal cupos  */

.title-form-cupos {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.title-form-cupos h1 {
  font-size: 27px;
  font-weight: bold;
}

.content-form {
  display: grid;
  flex-direction: row;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 20px;
}
.content-form-tenis{
  align-content: inherit;
  
}
.content-form-padel {
  display: flex;
  flex-direction: column;
}
.padel-list {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr auto;
  justify-content: flex-end;
}
.padel-list> div:nth-of-type(2) {
  margin-left: 8px;
}
.padel-list> svg:nth-of-type(1) {
  margin: 8;
  margin-inline: 13px;
  margin-top: 20px;
  cursor: pointer;
}
.form-column-1 {
  display: flex;
  gap: 20px;
  align-content: center;
  align-items: center;
}

.form-column-2 {
  display: flex;
  flex-direction: column;
}

.form-content-label {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  margin-top: 10px;
}

.form-content-input {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 5px 5px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  width: 100%;
}

.button-content-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  height: 10%;
}

.button-add-modal {
  border: none;
  border-radius: 8px;
  background-color: #bb0a13;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 20%;
}

.button-cancel-modal {
  border: none;
  border-radius: 8px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 20%;
}
.torneo-mapa-container {
  grid-column: span 3;
}

.scroll::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 4px;
}

.service-column::-webkit-scrollbar-thumb:hover {
  background: #494846;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #494846;
}

.scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

@media screen and (max-width: 1200px) {
  .torneo-event-content {
    grid-gap: 10px 20px;
    padding: 32px;
  }
}
@media (max-width: 900px) {
  .torneo-event-content {
    grid-template-columns: 1fr 1fr;
  }
  .torneo-event-general {
    grid-column: span 1;
  }
  .torneo-mapa-container,
  .torneo-event-preferencias {
    grid-column: span 2;
  }
  .torneo-btn_form-no-grid-colum {
    grid-column: span 1;
  }
}
@media screen and (max-width: 780px) {
  .container-image-creartorneo {
    display: none;
  }
}
@media (max-width: 750px) {
  .torneo-event-content {
    grid-template-columns: 1fr;
  }
  .torneo-event-general {
    grid-column: span 1;
  }
  .torneo-mapa-container,
  .torneo-event-preferencias {
    grid-column: span 1;
  }
}
@media (max-width: 680px) {
  .padel-list {
    display: flex;
    flex-direction: column;
  }
  .padel-list> div:nth-of-type(2) {
    margin-left: 0px;
  }
  .padel-list> svg:nth-of-type(1) {
    margin: 0;
  }
  .content-form-tenis,
  .content-form-padel {
    display: flex;
    flex-direction: column;
    justify-content: inherit;
    height: calc(100% - 86px);
  }
}

@media (max-height: 568px) and (max-width: 320px) {
  .torneo-event-general-information {
    grid-template-columns: none;
  }
  .event-preferences {
    display: flex;
    flex-direction: column;
    height: 61%;
    width: 100%;
    padding-top: 157px;
  }

  .torneo-event-general-information-inputs {
    width: 95%;
    align-self: center;
    margin-left: 9px;
  }

  .torneo-event-button {
    margin-bottom: 3px;
    font-size: 12px;
    width: 100%;
  }

  .torneo-event-photo {
    object-fit: cover;
  }
}

@media screen and (max-width: 480px) {
  .title-form-cupos h1 {
    font-size: 22px;
    margin-inline: 30px;
  }
  .torneo-btn_form {
    /* background: red; */
    position: fixed;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
    height: 105px;
    width: 90%;
    margin-bottom: 15px;
    justify-content: space-evenly;
    display: grid;
    grid-template-columns: 1fr;
    background-color: white;
  }
  .torneo-btn_form_tennis {
    /* background: red; */
    position: fixed;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
    height: 105px;
    width: 90%;
    justify-content: space-evenly;
    display: grid;
    grid-template-columns: 1fr;
    background-color: white;
  }
  .torneo-event-preferencias {
    grid-template-columns: 1fr;
  }
  .torneo-event-general-information {
    /* display: none; */
    grid-template-columns: none;
    max-height: 198px;
    width: 100%;
    /* max-height: 309px; */
    text-align: start;
    margin-top: 10px;
  }
  .event-general-information {
    margin-top: 10px;
  }

  #event-img {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-left: 20px;
  }

  .event-preferences {
    display: flex;
    flex-direction: column;
    height: 41%;
    width: 100%;
    margin-top: 149px;
  }

  .event-preferences h3 {
    align-self: flex-start;
    margin-left: 2.5%;
    font-size: 20px;
    font-weight: bold;
  }

  .preferences-inputs {
    width: 95%;
    align-self: center;
    display: flex;
    flex-direction: column;
  }

  .event-location {
    display: flex;
    flex-direction: column;
    height: 13%;
    width: 105%;
  }
  .location-information {
    flex-direction: column;
  }
  .map-information-event {
    flex-direction: column;
  }
  .torneo-event-content {
    width: 100%;
    height: 100vh;
    text-align: -webkit-center;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    padding-bottom: 150px;
    /* border: 1px solid rgb(27, 36, 29); */
  }
  .map-location-event {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 20%;
    padding-top: 1.5rem;
    margin-bottom: 10px;
  }

  .torneo-event-intext {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 8px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    margin-bottom: 1vw;
    width: 100%;
    margin-left: 0;
  }

  .torneo-event-intext-location {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 8px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    margin-bottom: 1vw;
    width: 100%;
    margin-left: 0;
  }

  .torneo-event-intext-region {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 8px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    margin-bottom: 1vw;
    width: 95%;
    margin-left: 0;
    height: 100%;
  }

  .torneo-cancelar-event-boton {
    font-size: calc(13px + 4 * ((100vw - 300px) / (1600 - 300)));
  }

  .torneo-crear-event-boton {
    font-size: calc(13px + 4 * ((100vw - 300px) / (1600 - 300)));
  }

  .torneo-event-button {
    margin-bottom: 3px;
    width: 100%;
  }

  .torneo-event-photo {
    object-fit: cover;
  }

  .torneo-event-general-information-inputs h3 {
    align-self: flex-start;
    margin-left: 2.5%;
    font-size: 20px;
    font-weight: bold;
  }

  .content-form {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    height: 60%;
    place-items: center;
  }
  .content-form-tenis,
  .content-form-padel {
    display: flex;
    flex-direction: column;
    justify-content: inherit;
    height: calc(100% - 86px);
  }
  .button-add-modal {
    width: 50%;
    font-size: calc(12.5px + 4 * ((100vw - 300px) / (1600 - 300)));
  }

  .button-cancel-modal {
    width: 50%;
    margin-left: 1rem;
    font-size: calc(12.5px + 4 * ((100vw - 300px) / (1600 - 300)));
  }

  .button-content-modal {
    height: 7%;
  }

  .form-content-input {
    font-size: calc(12.5px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
}
