.icon-instagram a {
  border: none;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  padding: 10px;
  border-radius: 8px;
}

.icon-facebook a {
  border: none;
  background: #3b5998;
  padding: 10px;
  border-radius: 8px;
}

.icon-youtube a {
  border: none;
  background: red;
  padding: 10px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .container-header-account {
    display: flex;
  }
}
