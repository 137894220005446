.shadow{
    border-radius: 4px;
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    margin-right: 36px;
    margin-left: 36px;
    /* margin-top: 1vw; */
    transition-duration: 500ms;
    cursor: pointer;
}

.shadow:hover {
    transform: scale(0.98);
}

.efectivo-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.efectivo-content h5{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    align-items: center;
    text-align: center;
    margin-top: 32px;
}

.efectivo-content p {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #949494;
    align-items: center;
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.efectivo-content h4{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 24px;
    align-items: center;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 21px;
    
}

.online-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.online-content h4{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 24px;
    text-align: left;
}

.shadow img{
    margin-top: 19px;
    margin-bottom: 14px;
    width: 170px;
    object-fit: contain;
}