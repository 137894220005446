.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  /* border: 1px solid #a0a096; */
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #b9ebff;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.week-calendar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.day {
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  width: 45px;
  height: 45px;
  text-align: center;
  font-weight: bold;
  font-family: revert;
  transition: 0.2s;
}

.day p {
  margin-top: 3px;
  color: darkslategrey;
}

/* Court Calendar */

.court-calendar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: flex-start;
  align-items: flex-start;
}

.court-calendar-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: baseline;
  align-items: flex-start;
  width: 100%;
  min-height: 250px;
  overflow-x: auto;
  transition: 0.5s;
}

.court-calendar-horizontal::-webkit-scrollbar {
  width: 8px;
  height: 15px;
}

.court-calendar-horizontal::-webkit-scrollbar-thumb {
  background: #727274;
  border-radius: 8px;
}

.court-calendar-horizontal::-webkit-scrollbar-thumb:hover {
  background: #57585B;
}

.court-calendar-horizontal::-webkit-scrollbar-thumb:active {
 background: #57585B;
}


.court-calendar-row-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-width: 22.5%;
  margin: 0px 3.5%;
  transition: 0.8s;
}

.court-day {
  width: 60px;
  height: 60px;
  text-align: center;
  font-weight: bold;
  font-family: revert;
  border-radius: 50%;
  padding: 15px;
  background-color: #dbdbe9;
  margin: 15px;
}

.court-day-disponibility {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.court-day-disponibility-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 250px;
  transition: 0.5s;
}

.court-day-am {
  display: flex;
  flex-direction: row;
}

.icon-horizontal {
  display: flex;
  flex-direction: column;
  align-self: center;
  transition: 0.5s;
}

.court-day-pm {
  display: flex;
  flex-direction: row;
}

.am-pm {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: calc(20px + 4 * ((100vw - 300px) / (1600 - 300)));
  margin-top: 0rem;
  width: 100%;
  padding-bottom: 0px;
  text-align: center;
  text-align: start;
}
.court-day-am-horizontal {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  align-items: center;
  transition: 0.5s;
}
.court-day-pm-horizontal {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  align-items: center;
  transition: 0.5s;
}

.editing-disponibility {
  min-width: 15vw;
  transition: 0.5s;
}

.empty {
  min-width: 75px;
  transition: 0.5s;
}
.time-picker-row {
  display: flex;
  flex-direction: row;
  margin: 0px 10px 0px 10px;
  align-items: center;
}
.time-picker-row-horizontal {
  display: flex;
  flex-direction: row;
  margin: 5px 10px 5px 10px;
  align-items: center;
}

@media screen and (max-width: 420px) {
  .court-calendar-row-horizontal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 200px;
    margin: 0px 3.5%;
    padding-left: 15%;
    margin-right: 15%;
    transition: 0.8s;
  }

  .editing-disponibility {
    min-width: 150px;
    transition: 0.5s;
  }
}
