.scroll-custom {
  display: grid;
}

@media (max-width: 320px) {
  .scroll-custom {
    display: flex;
  }
}
.jugadores-filtros-container {
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 15px));
  gap: 20px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
  padding: 16px 24px;
  width: 100%;
}

.jugadores-filtro {
  align-items: center;
  justify-content: space-between;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
}

.jugadores-header-tabla {
  flex-direction: row;
  background: rgba(241, 241, 255, 0.9);
  height: 50px;
}
.jugadores-header-tabla,
.jugadores-tabla-item {
  display: grid;
  grid-template-columns: 30px 50px repeat(7, auto);
  gap: 3px;
  /* total 700 ->  28.57, 14.28, 14.28, 14.28, 11.42, 17.14 */
}
.jugadores-tabla-admin {
  display: grid;
  grid-template-columns: 30px 50px calc(20.2% - 26.5px) repeat(6, calc(13.3% - 26.7px)) 80px;
  gap: 3px;
  /* total 700 ->  28.57, 14.28, 14.28, 14.28, 11.42, 17.14 */
}

.jugadores-tabla-item {
  display: grid;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  align-items: center;
}

.jugadores-tabla-header-title {
  display: flex;
  flex: auto;
  align-items: center;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #7474a0;
}

.jugadores-tabla-item-column {
  display: flex;
  flex: 1;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
}
.nombre {
  margin-right: 5px;
  margin-left: -19px;
}

.puntaje {
  margin-left: 1rem;
}

.historico {
  margin-left: 1rem;
}
.jugadores-tabla-primary-column {
  flex: 2.5;
}

.jugadores-input-container {
  display: grid;
  grid-template-columns: 20px calc(100% - 20px);
  width: 100%;
  height: 100%;
  padding: 12px 0;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  border-style: normal;
  border-color: #efeff7;
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: center;
  box-shadow: 9px 4px 17px rgba(156, 156, 203, 0.1);
}

.jugadores-search-input {
  display: flex-end;
  border: none;
}

.edit-foto-perfil {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: -48px;
  margin-right: 16px;
  transition-duration: 250ms;
  margin-left: 7rem;
  align-self: center;
}

.edit-foto-perfil:hover {
  transform: scale(0.975);
}

.edit-foto-perfil input {
  position: absolute;
  height: 40px;
  width: 40px;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}

.jugadores-tabla-historico {
  height: 2rem;
  object-fit: none;
}

.jugadores-filtro-usuarios {
  margin-bottom: auto;
  align-items: center;
  border-color: #efeff7;
  border-radius: 4px;
  border-style: solid;
  box-shadow: 9px 4px 17px rgba(156, 156, 203, 0.1);
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
}

.jugadores-filtros {
  align-items: center;
  border-color: #efeff7;
  border-radius: 4px;
  border-style: solid;
  box-shadow: 9px 4px 17px rgba(156, 156, 203, 0.1);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
  padding: 12px 0;
}
.jugadores-editar-usuarios {
  margin-left: 1rem;
}

.jugadores-eliminar {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.jugadores-editar {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.container-form {
  width: 100%;
  height: 100%;
  text-align: -webkit-center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* padding-left: 3rem;
    padding-top: 0.5rem; */
}

.create-club-title {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 20px 0px;
  text-align: center;
  align-self: center;
}

.form-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 37.7%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1.5rem;
}

.container-form-columns {
  display: flex;
  flex-direction: row;
}

.container-form-img-user {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* margin-left: calc(8px + 16 * ((100vw - 300px) / (1600 - 300))); */
  width: 100%;
}

.config-perfil-foto-jugador {
  height: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
  width: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
  border-radius: 50%;
  object-fit: cover;
  border: 8px solid white;
  margin-inline: auto;
}

.club-btn_form-jugadores {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 37.7%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1rem;
}

.jugadores-event-input {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  display: flex;
  flex: 1;
  width: 100%;
  /* height: 50%; */
  margin-bottom: 0.1rem;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  /* align-items: center; */
}

.jugadores-event-intext {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 8px 10px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  margin-bottom: 1vw;
  height: 46%;
  width: 85%;
  margin-left: 0;
}

.jugadores-crear-event-boton {
  position: relative;
  border: none;
  border-radius: 8px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 85%;
  height: 4vh;
}
.jugadores-cancelar-event-boton {
  position: relative;
  border: none;
  border-radius: 8px;
  background-color: #bb0a13;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 85%;
  height: 4vh;
}
/* .club-btn_form {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding-left: 3.5%;
  padding-right: 3.5%;
} */

.boton-izquierda {
  display: none;
}

.container-form-column-jugadores {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.jugadores-btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15.7%;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.5rem;
}

.jugadores-btn-cancelar {
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: 0.1rem;
}

.jugadores-btn-aceptar {
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: 0.1rem;
}

.scroll::-webkit-scrollbar,
.ReactModal__Overlay--after-open::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra (thumb) de scroll */
.scroll::-webkit-scrollbar-thumb,
.ReactModal__Overlay--after-open::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:active,
.ReactModal__Overlay--after-open::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.scroll::-webkit-scrollbar-thumb:hover,
.ReactModal__Overlay--after-open::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
.scroll::-webkit-scrollbar-track,
.ReactModal__Overlay--after-open::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-track:hover,
.ReactModal__Overlay--after-open::-webkit-scrollbar-track:hover,
.scroll::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

@media screen and (max-width: 1200px) {
  .jugadores-filtros-container {
    grid-template-columns: repeat(2, calc(50% - 10px));
  }
  .main-container-1200-desactivado {
    padding-left: 0;
    padding-right: 0;
  }
  .jugadores-tabla-item-column,
  .jugadores-tabla-header-title {
    font-size: 12px;
  }
  .jugadores-tabla-admin {
    display: grid;
    grid-template-columns: 30px 50px calc(20.2% - 26.5px) repeat(6, calc(13.3% - 26.7px)) 80px;
    gap: 3px;
  }
}
@media (max-height: 568px) and (max-width: 320px) {
  .container-form {
    height: 125vh;
    width: 100%;
  }
  .jugadores-btn-aceptar {
    flex: none;
    margin-bottom: 1rem;
  }

  .jugadores-filtros {
    font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
}

@media screen and (max-width: 480px) {
  .jugadores-filtros {
    font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  }

  .form-column {
    display: flex;
    flex-direction: column;
  }
  .create-club-title {
    display: flex;
    flex-direction: row;
    margin: 20px 0px 20px 0px;
    align-self: center;
    justify-content: flex-start;
    width: 100%;
  }

  .container-form-columns {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 1rem;
  }

  .container-form-column-jugadores {
    width: 100%;
    height: 40%;
    margin-top: 1rem;
    margin-right: -1rem;
    margin-bottom: -6px;
  }

  .edit-foto-perfil {
    align-self: center;
    margin-left: 7rem;
  }

  .boton-izquierda {
    display: block;
  }

  .club-event-label {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: calc(17px + 4 * ((100vw - 300px) / (1600 - 300)));
    margin-top: 0rem;
    width: 80%;
    padding-bottom: 0px;
    text-align: start;
  }
  .jugadores-event-input {
    margin-bottom: 1rem;
  }

  .jugadores-event-intext {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 8px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: calc(13px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    margin-bottom: 1vw;
    height: 46%;
    width: 85%;
    margin-left: 0;
  }

  .club-btn_form-jugadores {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
    /* padding-left: 3.5%;
      padding-right: 3.5%; */
  }

  .jugadores-crear-event-boton {
    margin-top: 1rem;
    position: relative;
    border: none;
    border-radius: 8px;
    background-color: #000022;
    color: #fff;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: "right";
    font-size: calc(15px + 4 * ((100vw - 300px) / (1600 - 300)));
    width: 85%;
  }
  .jugadores-btn {
    height: 100%;
    padding-bottom: 10px;
  }

  .jugadores-crear-event-boton {
    height: 50px;
  }

  .jugadores-cancelar-event-boton {
    display: none;
  }

  .jugadores-btn-aceptar {
    flex: none;
  }
}
