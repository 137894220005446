.footer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: black;
  align-items: stretch;
}

.footer-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  /* padding-left: 16vw;
    padding-right: 16vw; */
}

.footer-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 4vw;
}

.footer-container strong {
  font-weight: normal;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.356);
  padding-left: 6px;
}

.footer-container h2 {
  font-family: "Bitner Heavy Italic";
  font-weight: bold;
  font-size: calc(20px + 4 * ((100vw - 300px) / (1600 - 300)));
  color: #ffffff;
  margin: 0px;
  padding-bottom: 8px;
}

.footer-container span,
.footer-container a {
  font-family: "Objet Regular Italic";
  font-weight: normal;
  color: #ffffff;
  font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
  letter-spacing: 0;
  margin-top: 4px;
  transition-duration: 0.4s;
  transition: all 0.2s ease-in-out;
}

.footer-container span:hover,
.footer-container a:hover {
  font-family: "Objet Bold Italic";
  color: #a1be11;
  cursor: pointer;
  text-decoration: none;
  transform: scale(0.98);
}

.footer-copyright {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 90px;
}

.footer-copyright p {
  font-family: "Objet Regular Italic";
  font-weight: normal;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
}

.login-sesion {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000022;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 200px;
  margin-top: 30px;
  transition-duration: 250ms;
}

.login-sesion:hover {
  color: #505085;
}

/* Moviles */
@media (max-width: 920px) {
  .footer-content {
    padding-left: 8vw;
    padding-right: 8vw;
    flex-direction: column;
    padding-top: 24px;
  }
  .footer-copyright {
    padding-bottom: 40px;
  }
  .footer-container {
    align-items: flex-start;
  }
  .footer-container span,
  .footer-container a {
    margin: 0;
  }
  .footer-container h2 {
    font-size: 18px;
  }
}
