.realtime-counts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: rgba(241,241,249,0.45);
  margin-top: 100px;
  background-image: url("../assets/tenisback.jpg");
}

.realtime-counts-container h2 {
  font-family: "Bitner Heavy Italic";
  font-weight: bold;
  font-size: calc(28px + 4 * ((100vw - 300px) / (1600 - 300)));
  color: black;
  letter-spacing: 0;
}
.realtime-courts-image{
  width: 100%;
}

.realtime-counts-items {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  width: 100%;
  max-width: 480px;
  padding: 0 20px;
}

.realtime-counts-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.realtime-counts-item-container h3 {
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: calc(32px + 4 * ((100vw - 300px) / (1600 - 300)));
  color: #000022;
  margin: 0;
  padding: 0;
}

.realtime-counts-item-container h4 {
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: calc(16px + 4 * ((100vw - 300px) / (1600 - 300)));
  color: #4D4D64;
  text-align: center;
  margin: 0;
  padding: 0;
}

.counterOn{
  animation: visible 2s;
}

/* animation counter */
@keyframes visible { 
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/* Moviles */
@media (max-width: 920px){

  .realtime-counts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(241,241,249,0.45);
    text-align: center;
  }
  
  .realtime-counts-container h2 {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
    font-weight: normal;
    letter-spacing: 0;
  }

  .realtime-counts-items {
    flex-direction: column;
  }

  .realtime-counts-item-container {
    margin-top: 16px;
  }

}