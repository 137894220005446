.main-container-desactivado {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px;
  background-color: #f5f6fa;
  overflow: auto;
  transition-duration: 0.5s;
  width: 100%;
}
.detailEvent-toolsButtons-container {
  width: 100%;
  position: absolute;
  right: 0;
  display: flex;
  gap: 20px;
  max-width: 460px;
}
.detailEvent-toolsButtons-container-cuadros {
  width: 100%;
  height: fit-content;
  position: sticky;
  top: 0px;
}

.detailEvent-toolsButtons-container-bases {
  width: 100%;
  height: fit-content;
  position: sticky;
  top: 0px;
}

.detailEvent-toolsButtons-container-bases h2 {
  margin: 0;
  color: #e2e2e2;
  font-size: 16px;
}
.detailEvent-toolsButtons-container > div {
  margin-bottom: 16px;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-weight: 700;
  color: white;
  display: inline-block;
  background-color: #1275a5;
}

.inputfile:focus + label,
.inputfile + label:hover {
  color: #28a5e4;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.detailEvent-toolsButtons,
.inputfile + label {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 12px 24px;
  color: #2085b7;
  line-height: 15px;
  margin-bottom: 1rem;
}
.detailEvent-toolsButtons-disabled{
  cursor: auto;
  opacity: 0.6;
  color: #757575;
}
.detailEvent-toolsButtons-selected {
  color: #28a5e4;
  transform: scale(1.2);
  transform-origin: right;
}
.detailEvent-toolsButtons{
  text-decoration: none;
}
.detailEvent-toolsButtons:hover {
  background-color: #f1f1f1;
  color: #28a5e4;
  text-decoration: none;
}
.detailEvent-toolsButtons-disabled:hover{
  color: #757575;
}
.crear-event-container {
  background: #f5f6fa;
  width: 50vw;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin-left: 10vw;
  margin-right: 10vw;
}

.mensaje p {
  font-size: 1.25rem;
  text-align: center;
}

.crear-event-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: stretch;
}

.crear-event-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 30px;
  margin-left: 30px;
}

.crear-event-img {
  object-fit: contain;
  position: relative;
  width: 50%;
  margin-right: 30px;
}

.crear-event-img img {
  object-fit: contain;
  position: absolute;
  width: 100%;
  align-content: flex-start;
}

.crear-event-titulo {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.crear-event-label {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}

.crear-event-intext {
  padding: 8px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 2vw;
  width: 350px;
}

.crear-event-input {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  /* width: 400px; */
}

.crear-event-boton {
  position: relative;
  border: none;
  border-radius: 8px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.crear-event-boton:hover {
  background: #000022;
  opacity: 0.6;
}

/* Detalle Evento */

.boton-atras {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.button-back {
  z-index: 1000;
  position: absolute;
  top: 87px;
}

.button-back-style {
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 40px;
}

.detalle-content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
  background: white;
  align-items: flex-start;
  justify-content: flex-start;
}

.detail-container {
  background: #f5f6fa;
  display: flex;
  height: 90%;
  flex-direction: column;
  margin-right: 32px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  text-align: left;
  background: white;
  align-items: flex-start;
  justify-content: flex-start;
}
.detail-event-title {
  width: calc(100% - 450px);
  min-width: 500px;
}
.detail-event-container {
  background: #f5f6fa;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  text-align: left;
  background: white;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
}

.detalle-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

/* .detalle-row img {
  vertical-align: middle;
    border-style: none;
    width: 100%;
    height: 95vh;
    object-fit: cover;
} */

.detalle-portada {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.detalle-alert {
  background-color: #3f2b8e;
  text-align: center;
}

.detalle-alert p {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: white;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 1rem;
}

.detalle-alert-mapa {
  background-color: #3f2b8e;
  text-align: center;
  width: 100%;
}

.detalle-alert-mapa p {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: white;
  text-align: center;
  margin-top: 12px;
}

.foto-evento {
  width: 485px;
  object-fit: contain;
  /* margin-right: 26px;
    margin-bottom: 25px; */
}

.detalle-evento {
  padding-top: 40px;
  padding-left: 40px;
  text-align: "left";
  width: 100%;
  padding: 0;
  display: flex;
  gap: 100px;
}

.nopadding {
  padding: 0;
}
.detalle-evento2-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.detalle-evento2 {
  display: grid;
  padding-top: 20px;
  padding-left: 40px;
  text-align: "left";
  grid-template-columns: 1fr 1fr;
}
.detalle-fechas {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.fecha-container-column h1 {
  color: black;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
}

.fecha-container-column h2 {
  color: #989898;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}

.detalle-fechas h3 {
  color: #989898;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
  text-align: start;
  flex: 2;
  min-width: 100%;
}
.detalle-cupos-grid {
  width: 70%;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  gap: 20px;
  place-self: end;
  grid-row: span 2;
  flex-wrap: wrap;
  justify-content: center;
}
.detalle-cupos {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dcec001f;
  border-radius: 12%;
  padding: 6px 8px;
  flex: 1;
  max-height: 75px;
  min-width: 140px;
}
.detalle-cupos h3 {
  color: #1d1d1d;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
  text-align: center;
}

.select-categoria {
  border-radius: 4px;
  min-width: 85px;
  border-color: "#efeff7";
  font-size: 16px;
  font-weight: normal;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  margin-right: 5px;
}

.fechas-container {
  display: flex;
  flex-direction: row;
}

.fecha-container-column {
  display: flex;
  flex-direction: column;
}

.detalle-evento h1 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 32px;
}

.detalle-evento h2 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #817777;
  text-align: left;
  /* padding: 30px; */
}

.detalle-evento h3 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: black;
  text-align: left;
  /* padding: 30px; */
}

.detalle-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  text-align: "left";
  overflow: auto;
  padding: 20px 32px 0px 32px;
}

.detalle-organizadores {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 42px;
  padding-bottom: 40px;
}

.detalle-organizadores h3 {
  color: #989898;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
  text-align: left;
  margin-left: 20px;
}

.detalle-organizadores img {
  width: 120px;
  object-fit: contain;
  margin-left: 16px;
}

.detalle-organizadores-inscripcion {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.detalle-inscribirse {
  position: relative;
  border: none;
  border-radius: 8px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 60px;
}

.detalle-inscripcion {
  align-self: flex-end;
  margin: 50px;
}

/* Moviles */

.mensaje-container {
  display: flex;
  margin-top: 10px;
}

.mensaje-container h2 {
  font-size: 14px;
  font-weight: normal;
  color: #6b6b6bce;
}

.mensaje-container h2 b {
  font-size: 14px;
}

.detalle-evento-botones {
  margin: 0px;
  color: #2085b7;
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.detailEvent-toolsButtons:hover .detalle-evento-botones {
  color: #28a5e4;
}
.pdf-style {
  min-width: 100%;
}
.form-ModalCupos {
  height: 100%;
  padding: 12px 12px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.events-upcoming:hover {
  transform: scale(0.98);
  box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
}

.events-completed {
  opacity: 0.5;
}

.events-completed:hover {
  transform: scale(0.98);
  box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
  opacity: 1;
}

.events-completed button {
  position: absolute;
  background-color: black;
  border: none;
  color: white;
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  padding: 15px 20px 15px 20px;
  transition-duration: 0.4s;
  font-size: 17px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.events-completed:hover button {
  opacity: 1;
}

@media (max-width: 1300px) {
  .detailEvent-toolsButtons-container {
    position: relative;
    place-self: end;
  }
  .detail-event-title {
    width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 1100px) {
  .detalle-cupos-grid {
    grid-row: span 2;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: start;
    margin-block: 20px;
    place-self: start;
  }
  .detalle-evento2 {
    grid-template-columns: 1fr;
  }
  .detalle-evento {
    justify-content: space-between;
  }
}
@media (max-width: 920px) {
  .detalle-cupos {
    width: 100%;
  }
  .detailEvent-toolsButtons-container {
    place-self: center;
  }
  .button-back-style {
    font-size: 30px;
  }
  .mensaje-container h2 {
    font-size: 12px;
  }

  .mensaje-container h2 b {
    font-size: 12px;
  }

  .detalle-inscripcion {
    margin: 50px 20px 50px 20px;
  }

  .detalle-inscribirse {
    height: 100%;
    padding: 10px 30px 10px 30px;
    font-size: 12px;
  }

  .detalle-organizadores h3 {
    font-size: 10px;
  }

  .detalle-organizadores img {
    width: 100px;
  }

  .detalle-evento-botones {
    font-size: 12px;
  }

  .detalle-evento h1 {
    font-size: 20px;
  }

  .detalle-evento h2 {
    font-size: 14px;
  }

  .detalle-evento h3 {
    font-size: 14px;
  }

  .detalle-fechas h3 {
    font-size: 10px;
  }

  .fecha-container-column h1 {
    font-size: 15px;
  }

  .fecha-container-column h2 {
    font-size: 12px;
  }

  .detalle-cupos h3 {
    font-size: 10px;
  }

  .select-categoria {
    font-size: 12px;
    height: 25px;
  }

  .main-container-activado {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    overflow: hidden;
    transition-duration: 0.5s;
    max-height: calc(100vh - 66px);
    max-height: 100%;
  }

  .main-container-desactivado {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    transition-duration: 0.5s;
  }
  .detail-event-container {
    background: #f5f6fa;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    text-align: left;
    background: white;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .detail-container {
    background: #f5f6fa;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    margin-right: 32px;
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    text-align: left;
    background: white;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .detalle-inscribirse {
    font-size: 15px;
  }
  .base-torneo {
    height: 100vh;
    width: 100vw;
  }
  .button-back {
    display: flex;
  }
  .detailEvent-toolsButtons-container-bases {
    /* height: 111vh; */
    position: absolute;
    width: 19%;
    margin-top: 144px;
    /* display: flex; */
    flex-direction: column-reverse;
  }

  .detailEvent-toolsButtons-container-bases label {
    pointer-events: none;
    font-size: 16px;
    margin: 0;
  }

  .cuadros-event-intext {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 8px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: calc(13px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    margin-bottom: 1rem;
    width: 105%;
    margin-left: 0;
  }
  .detailEvent-toolsButtons,
  .inputfile + label {
    margin-bottom: 1rem;
  }
}
@media (max-width: 800px) {
  .detailEvent-toolsButtons-container-cuadros {
    height: 100vh;
    position: absolute;
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 768px) {
  .detalle-body {
    padding: 20px 20px 0px 20px;
  }
  .pdf-style {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 185%; */
  }

  .pdf-style-cuadro {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 185%; */
  }

  .pdf-style-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 100%; */
  }
  .pdf-style-3 > canvas {
    width: 34vh !important;
    height: 43vh !important;
  }
  .detailEvent-toolsButtons-container-cuadros {
    height: 100vh;
    position: absolute;
    width: 85% !important;
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 425px) {
  .detalle-evento2 {
    display: flex;
    flex-direction: column;
  }
  .detailEvent-toolsButtons {
    padding: 8px 12px;
  }
  .detalle-cupos-grid {
    width: 100%;
  }
  .pdf-style-cuadro {
    margin: auto;
    width: 175% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 185%; */
  }
  .pdf-style {
    margin: auto;
    width: 206% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 185%; */
  }

  .pdf-style-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 100%; */
  }
  .pdf-style-3 > canvas {
    width: 34vh !important;
    height: 43vh !important;
  }

  .pages-style {
    width: 100%;
  }

  .form-ModalCupos {
  padding: 0;
  height: 95%;
  }
}
@media (max-width: 375px) {
  .pdf-style {
    margin: auto;
    width: 206%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 185%; */
  }

  .pdf-style-cuadro {
    margin: auto;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 185%; */
  }

  .pdf-style-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 100%; */
  }
  .pdf-style-3 > canvas {
    width: 34vh !important;
    height: 43vh !important;
  }

  .detailEvent-toolsButtons-container-bases h2 {
    margin: 0;
    color: #e2e2e2;
    font-size: 10px;
  }
  .detailEvent-toolsButtons-container-bases label {
    pointer-events: none;
    font-size: 10px !important;
    margin: 0;
  }
}
