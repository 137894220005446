.edit-perfil-club-content, .edit-perfil-club-content-welcome {
  width: 100%;
  max-width: 1400px;
  text-align: -webkit-center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  place-self: center;
}

.perfil-container-column {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  grid-gap: 10px 20px;
}

.perfil-club-row-1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 37.7%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.perfil-club-row-2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 37.7%;
  justify-content: space-between;
  align-items: center;
}

.perfil-club-row-3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 37.7%;
  justify-content: space-between;
  align-items: center;
}

#club-container-image {
  grid-row: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.perfil-textarea {
  grid-column: span 2;
  display: grid;
  grid-template-rows: auto 1fr 16px;
  font-family: "Objet Regular Italic";
  flex: 1 1;
  width: 100%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: flex-start;
}
.perfil-mapa-container {
  grid-column: span 3;
  display: flex;
  flex-direction: column;
}
#club-information-container {
  display: flex;
  flex-direction: column;
  width: 90%;
}

#edit-foto-perfil-club {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: -48px;
  margin-right: 16px;
  transition-duration: 250ms;
  margin-left: 7rem;
  align-self: center;
  transform: scale(0.975);
  cursor: pointer;
}

#edit-foto-perfil-club:hover {
  transform: scale(0.9);
}

#edit-foto-perfil-club input {
  position: absolute;
  height: 40px;
  width: 40px;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}

#club-logo {
  height: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
  width: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
  border-radius: 50%;
  object-fit: cover;
  border: 8px solid white;
  /* margin-top: 20px;
    margin-bottom: 20px; */
  /* object-fit: cover;
    width: 50%;
    height: 100%;
    border-radius: 18px; */
}

#inputs-name-phone {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#inputs-about {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#inputs-location {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.perfil-club-input {
  min-height: 105px;
}
.perfil-club-input {
  font-family: "Objet Regular Italic";
  flex: 1 1;
  width: 100%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: flex-start;
}

/* .perfil-club-input {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  display: flex;
  width: 100%;
  height: 40%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
} */
.perfil-club-input-2 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  display: flex;
  flex: 1;
  height: 40%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
}

#inputs-location .perfil-club-input-3:nth-last-of-type(1) {
  margin-left: 2.5%;
}

.perfil-club-input-3 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  display: flex;
  flex: 1;
  width: 97.5%;
  height: 40%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
}
#inputs-location .perfil-club-input-2:nth-last-of-type(1) {
  margin-left: 2.5%;
}
#inputs-location .perfil-club-input-2:nth-last-of-type(2) {
  margin-left: 2.5%;
}

#inputs-name-phone .perfil-club-input:nth-last-of-type(1) {
  margin-left: 2.5%;
}

.perfil-club-btn {
  display: flex;
  padding-top: 1rem;
  justify-content: flex-end;
  width: 100%;
  /* height: 66px; */
  /* padding-left: 27.5%; */
  padding-right: 3.5%;
  padding-bottom: 2rem;
  /* margin-top: 1rem; */
}

.service-club-btn {
  display: flex;
  padding-top: 1rem;
  justify-content: flex-end;
  width: 100%;
  /* height: 66px; */
  /* padding-left: 27.5%; */
  /* padding-right: 3.5%; */
  padding-bottom: 2rem;
  /* margin-top: 1rem; */
}

.club-container-profile {
  display: flex;
  height: 100%;
  min-height: 300px;
  place-content: center;
  overflow: auto;
  margin-top: 1rem;
}

.club-container-profile::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.club-container-profile::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 4px;
}

.service-column::-webkit-scrollbar-thumb:hover {
  background: #494846;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.club-container-profile::-webkit-scrollbar-thumb:active {
  background-color: #494846;
}

.club-container-profile::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.information-club-button {
  height: auto;
  text-align: center;
  padding: 12px 32px 12px 32px;
  border-radius: 8px;
  margin-block: 16px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  transition-duration: 200ms;
  cursor: pointer;
}

.information-club-button:hover {
  transform: scale(0.98);
  box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
}

.information-service-button {
  /* right: 3.5%; */
  height: auto;
  position: relative;
  text-align: center;
  width: 35%;
  padding: 12px 32px 12px 32px;
  margin: 12px 0px;
  border-radius: 8px;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  transition-duration: 200ms;
  cursor: pointer;
}

.information-service-button:hover {
  transform: scale(0.98);
  box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
}

.button-charging {
  margin-left: 15px;
}

.information-club-button p {
  margin-bottom: 0px;
}

.edit-perfil-club-intext-2 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 10px;
  display: flex;
  border: 1.5px solid #202020;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  width: 100%;
  margin-left: 0;
}

.perfil-club-intext,
.perfil-club-intext-2,
.perfil-club-error {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 10px;
  display: flex;
  border: 2px solid #95c11f;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  width: 100%;
  margin-left: 0;
}

.perfil-club-error {
  border: 2px solid red;
}

.perfil-club-error:focus-visible,
.perfil-club-error-2:focus-visible,
.perfil-club-intext:focus-visible,
.perfil-club-intext-2:focus-visible,
.perfil-club-intext:focus,
.perfil-club-intext-2:focus {
  outline: none;
}

.perfil-club-label {
  position: relative;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0rem;
  width: 100%;
  padding-bottom: 0px;
  text-align: center;
  text-align: start;
}
.mapa-container {
  height: 200px;
  width: 100%;
}

.config-perfil-club-foto {
  height: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
  width: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
  border-radius: 50%;
  object-fit: cover;
  border: 8px solid white;
}

.perfil-club-errorMessage {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: red;
  font-size: calc(6px + 4 * ((100vw - 300px) / (1600 - 300)));
  text-align: start;
  margin-top: 4px;
}

@media screen and (max-width: 800px) {
  .perfil-club-row-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 37.7%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  #club-information-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .information-club-button {
    width: none;
  }
}

@media screen and (max-width: 740px) {
  .information-club-button {
    width: 48%;
  }
}

@media screen and (max-width: 480px) {
  .perfil-container-column {
    margin-top: 30px;
    display: flex;
  }
  .club-container-profile {
    margin-top: 0px;
  }

  .perfil-club-row-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 37.7%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  .perfil-club-row-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 37.7%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
  .perfil-club-row-3 {
    margin-bottom: 1rem;
  }

  /* #inputs-name-phone{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-left: 2rem;
        margin-right: 1rem;
    } */

  .container-form-img {
    width: 30%;
  }

  #club-information-container {
    width: 100%;
  }

  .perfil-club-label {
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
  }

  #inputs-name-phone {
    display: flex;
    flex-direction: column;
  }

  #inputs-name-phone .perfil-club-input:nth-last-of-type(1) {
    margin-left: 0%;
  }

  .perfil-club-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }

  .information-club-button {
    right: 3.5%;
    height: auto;
    position: relative;
    text-align: center;
    width: 93%;
    padding: 12px 32px 12px 32px;
    margin-bottom: 25px;
    border-radius: 8px;
    background-color: #000022;
    color: #fff;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-weight: bold;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-self: flex-end;
  }

  #club-logo {
    height: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
    width: calc(160px + 24 * ((100vw - 300px) / (1600 - 300)));
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #e3e3e3;
    margin: 0px 0px 1rem 0px;
  }
  .perfil-club-input {
    width: 97.5%;
  }
  .perfil-club-input-2 {
    width: 100%;
  }

  .mapa-container {
    height: 250px;
    width: 100%;
  }

  .perfil-club-intext-2 {
    width: 100%;
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
  }

  .edit-perfil-club-intext-2 {
    width: 100%;
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
  .perfil-club-intext {
    width: 100%;
    font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
  .information-service-button {
    width: 100%;
  }
}
