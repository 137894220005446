.horizontal-slider {
  width: 100%;
  max-width: 500px;
}
.example-track {
  height: 4px;
  top: 8px;
  background-color: black;
}
.example-thumb {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0px 1px 4px 0px #00000099;

}
.example-mark {
  width: 8px;
  height: 8px;
  border: 2px solid rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
