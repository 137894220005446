@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.scroll {
  overflow: auto;
}

.scroll::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #494846;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #494846;
}

.scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

button:focus{
  outline: inherit;
}

.ReactModalPortal{
  position: absolute;
  z-index: 100;
}
