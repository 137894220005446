/* Customize the label (the container) */
.container-check {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container-check-medium {
  font-size: 16px;
  padding-left: 25px;
}
/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-check {
  position: absolute;
  border-radius: 100%;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkmark-check-medium {
  height: 18px;
  width: 18px;
}
.checkmark-check-square {
  border-radius: 2px;
}

.container-check input ~ .checkmark-check {
  background-color: #eee;
  border: 1px solid rgb(201, 201, 201);
}
/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark-check {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark-check {
  background-color: #2085b7;
}
.container-check:hover input:checked ~ .checkmark-check {
  background-color: #1c95d1;
}

/* Create the checkmark-check/indicator (hidden when not checked) */
.checkmark-check:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark-check when checked */
.container-check input:checked ~ .checkmark-check:after {
  display: block;
}

/* Style the checkmark-check/indicator */
.container-check .checkmark-check:after {
  background-color: white;
  height: 11px;
  width: 11px;
  border-radius: 100%;
}
.container-check .checkmark-check-medium:after {
  height: 10px;
  width: 10px;
}
.container-check .checkmark-check-square:after {
  border-radius: 2px;
}