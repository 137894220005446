.SingleCalendar {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  width: 100%;
}
.SingleAgend {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  width: 100%;
}
.TimeInput {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 5px;
}
.TimeInput-fields-change-pm{
    background-color: #fdfdfd11;
}
.TimeInput-fields-change-am{
    background-color: #c2aa001a;
}
.TimeInput-fields-label {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
}
.TimeInput-fields-change {
  
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}
.TimeInput-fields-change:hover {
    background-color: #ffffff1f;
}
.TimeInput-field-am{
    background-color: #e9c057;
}
.TimeInput-field-pm{
    background-color: #368eff;
}
.TimeInput-field {
  display: flex;
  grid-template-rows: auto auto auto;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
}
.TimeInput-input-container {
  background-color: #f1f1f1;
  border-radius: 10px;
  shape-margin: 20px;
  margin: 0 5px;
}
.TimeInput-input-container-am {
  background-color: #ddaf38;
}
.TimeInput-input-container-pm {
  background-color: #2471d4;
}
.TimeInput-padding {
  padding: 12px;
}
.TimeInput-AmPm-Selected {
  background-color: rgba(255, 255, 255, 0.192);
}
.TimeInput-AmPm {
  margin-top: 8px;
  cursor: pointer;
  text-align: center;
}
.SingleCalendar-firstregion {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SingleCalendar-divImg {
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
}
