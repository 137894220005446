.custom-input-container {
  min-height: 91px;
  font-family: "Objet Regular Italic";
  width: 100%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: flex-start;
  display: flex;
}
.custom-input-label {
  position: relative;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0rem;
  width: 100%;
  padding-bottom: 0px;
  text-align: center;
  text-align: start;
}
.custom-select-error{
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 10px;
  display: flex;
  border: 1.5px solid #ff0000;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  margin-left: 0;
}
.custom-select-t,
.custom-input,
.custom-input-error {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 10px;
  display: flex;
  border: 1.5px solid #202020;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  margin-left: 0;


}
.custom-select-t,
.custom-select-error {
  min-height: 41.7px;
  padding:0;

}

.custom-input-error {
  border: 2px solid red;
}
.custom-input:focus-visible,
.custom-input:focus,
.custom-input-error:focus-visible,
.custom-input-error:focus {
  outline: none;
}

.custom-input-errorMessage {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: red;
  font-size: calc(6px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 100%;
  margin-top: 4px;
  text-align: start;
}

@media screen and (max-width: 480px) {
  .custom-input-label,
  .custom-input-errorMessage {
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
  }
}
