.lines-White:nth-child(odd):not(:last-child)::after {
  border-top-color: #565656 !important;
  border-top-width: 2px !important;
}
.lines-White:nth-child(even)::before {
  border-top: 2px solid #565656 !important;
}

[dir='ltr'] .lines-White:nth-child(odd):not(:last-child)::after {
  border-right: 2px solid #565656 !important;
}
[dir='ltr'] .lines-White:nth-child(even)::after {
  border-right: 2px solid #565656 !important;
}
.lines-White:nth-child(even)::after {
  border-bottom: 2px solid #565656 !important;
}
