.socios-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: rgba(241, 241, 249, 0.45);
    align-items: center;
    padding-top: 50px;
    padding-bottom: 10px;
}

.socios-content h1 {
    align-items: center;
    font-size: calc(24px + 8 * ((100vw - 300px) / (1600 - 300)));
    font-family: "Bitner Heavy Italic";
    font-weight: bold;
}

.socios-img-content {
    padding: 24px;
    width: 320px;
    height: 300px;
    object-fit: contain;
}

.swiper-socios {
    height: 320px;
}

@media (max-width: 920px) {
    .socios-img-content {
        width: auto;
        padding: 15px 50px 15px 50px;
    }

}