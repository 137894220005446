.event-content-land {
  display: flex;
  flex-direction: column;
  /* padding-left: 18vw;
  padding-right: 8vw;
  padding-top: 8vw; */
  padding: 8vw;
  background-color: black;
}
.event-text-container {
  display: flex;
  padding-left: 4vw;
  flex-direction: column;
  justify-content: center;
}

.event-text-container p {
    font-family: "Objet Normal Italic";
    font-size: calc(20px + 4 * ((100vw - 300px) / (1600 - 300)));
    text-align: center;
    color: #a1be11;
    letter-spacing: 0;
}

.event-text-container h1 {
    font-family: "Bitner Heavy Italic";
    font-weight: bold;
    font-size: calc(32px + 8 * ((100vw - 300px) / (1600 - 300)));
    text-align: center;
    color: #a1be11;
    letter-spacing: 0;
}

  .event-text-container button:hover {
    background-color: #878f99; 
    color: white;
  }

  .event-screenshots-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .event-button {
    background-color: #a1be11; 
    border: none;
    color: black;
    border-radius: 30px;
    text-align: center;
    align-self: center;
    text-decoration: none;
    padding: 20px;
    width: 245px;
    transition-duration: 0.4s;
    font-family: "Objet Regular";
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    transition: all 0.2s ease-in-out;
  }

  .event-button:hover {
    transform: scale(0.98);
    color: white;
}

  .event-screenshots {
    width: 300px;
    object-fit: contain;
}

/* Moviles */
@media (max-width: 920px){

  .event-content-land {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .event-text-container {
    padding: 0px;
    align-items: center;
    margin-top: 20px;
  }
  .event-text-container p {
    text-align: center;
    letter-spacing: 0;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    margin-top: 32px;
}

.event-text-container h1 {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    margin: -15px;
}

.event-text-container button {
    background-color: #000022; 
    border: none;
    color: white;
    border-radius: 6.4px;
    text-align: center;
    align-self: center;
    text-decoration: none;
    padding: 15px;
    width: 239.2px;
    transition-duration: 0.4s;
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .event-screenshots {
    max-width: 300px;
    max-height: 70vh;
    margin-bottom: 20px;
  }

  .event-screenshots-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}