.conf-content-land {
    display: flex;
    flex-direction: row;
    /* padding-left: 18vw;
    padding-right: 8vw;
    padding-top: 8vw; */
    padding: 8vw;
}
.conf-text-container {
    display: flex;
    flex:1;
    padding-left: 4vw;
    flex-direction: column;
    justify-content: center;
}

.conf-text-container p {
    font-family: "Objet Bold Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    font-size: 18px;
    text-align: right;
    color: #6F767A;
    letter-spacing: 0;
    margin-top: 32px;
}

.conf-text-container h1 {
    font-family: "Bitner Heavy Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    text-align: right;

    color: #333;
    letter-spacing: 0;
}


  .conf-screenshots-container {
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 32px;
    padding-bottom: 32px;
  }

  .conf-screenshots {
    width: 300px;
    object-fit: contain;
}

/* Moviles */
@media (max-width: 920px){

  .conf-content-land {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .conf-text-container {
    display: flex;
    flex:1;
    padding-left: 4vw;
    flex-direction: column;
  }
  .conf-text-container p {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    text-align: center;
    color: #6F767A;
    letter-spacing: 0;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    margin-top: 32px;
}

.conf-text-container h1 {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin: -15px;
}


  .conf-screenshots {
    width: 50vw;
  }

  .conf-screenshots-container {
    margin-left: 0px;
  }
}