.home-header-buttons-change {
  width: 100%;
  background-color: black;
  position: static;
  padding: 15px;
  z-index: 0;
}

.home-header-button {
  font-family: "Segoe UI";;
  font-weight: bold;
  color: white;
  text-decoration: none;
  margin: 16px 0px 16px 0px;
  font-size: calc(15px + 4 * ((100vw - 300px) / (1600 - 300)));
  cursor: pointer;
  transition-duration: 0.4s;
  transition: all 0.2s ease-in-out;
}

.home-header-button a {
  text-decoration: none;
  transition-duration: 0.4s;
  transition: all 0.2s ease-in-out;
}

.home-header-button a:hover {
  color: #a1be11;
}

.home-header-button:hover {
  transform: scale(0.98);
}

.home-header-button-event {
  font-family: "Segoe UI";
  font-weight: bold;
  color: white;
  text-decoration: none;
  margin: 16px 0px 16px 0px;
  font-size: calc(15px + 4 * ((100vw - 300px) / (1600 - 300)));
  cursor: pointer;
  animation: throb 2s;
  animation-iteration-count: infinite;
  transition-duration: 0.4s;
  transition: all 0.2s ease-in-out;
}

.home-header-button-event a {
  text-decoration: none;
  transition-duration: 0.4s;
  transition: all 0.2s ease-in-out;
}

.home-header-button-event a:hover {
  color: #a1be11;
}

.home-header-button-event:hover {
  transform: scale(0.98);
}

/* Se utiliza cuando se acerquen eventos importantes! */

/* @keyframes throb {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
    font-weight: 500;
    color: #000022;
  }
  100% {
    transform: scale(1);

  }
} */

/* .home-header-button::after {
  content: "";
  display: block;
  height: 3px;
  background-color: #AAC130;
  transform: scale(0,1);
  transform-origin: center;
  transition: transform .25s;
}

.home-header-button:hover::after {
  transform: scale(1,1);
} */

/* .home-header-button-event::after {
  content: "";
  display: block;
  height: 3px;
  background-color: #AAC130;
  transform: scale(0,1);
  transform-origin: center;
  transition: transform .25s;
}

.home-header-button-event:hover::after {
  transform: scale(1,1);
} */

.home-header-button-sticky {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: white;
  text-decoration: none;
  margin: 24px;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  cursor: pointer;
}

.home-header-button-sticky::after {
  content: "";
  display: block;
  height: 3px;
  background-color: #ffffff;
  transform: scale(0, 1);
  transform-origin: center;
  transition: transform 0.25s;
}

.home-header-button-sticky:hover::after {
  transform: scale(1, 1);
}

.home-header-button:visited {
  color: #555555;
}

.home-header-premium {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: #967c24;
  text-decoration: none;
  margin-left: 54px;
  font-size: 14px;
}

.home-header-button:visited {
  color: #967c24;
}

.home-logo {
  cursor: pointer;
  position: absolute;
  left: 0px;
}

.home-logo-sticky {
  width: 200px;
  width: calc(120px + 50 * ((100vw - 300px) / (1600 - 300)));
  padding-bottom: 10px;
  cursor: pointer;
  margin-top: -40px;
}
.container-header-account {
  color: #DCEC00;
  display: flex;
  gap: 10px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  padding: 15px;
  z-index: 1;
}

.home-header-account {
  font-family: "Objet Normal Italic";
  font-weight: normal;

  font-size: calc(16px + 4 * ((100vw - 300px) / (1600 - 300)));
  color: #000022;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  justify-self: self-end;
  transition-duration: 250ms;
  margin-bottom: 10px;
  /* background-color: greenyellow; */
  border-radius: 25%;
  margin: 5px;
}

.home-header-account:hover {
  color: #a1be11;
}

.home-header-account-sticky {
  font-family: "Segoe UI";
  font-weight: bold;
  color: white;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  justify-self: self-end;
  transition-duration: 250ms;
  gap: 5px;
}

.home-header-account-sticky:hover {
  color: #a1be11;
}

/* .home-header-account:hover {
  color: #505085;
} */

.icons-footer {
  /* margin-left: 10px; */
  font-size: 30px;
}

/* Prueba navegacion */
.home-header-account li {
  list-style: none;
  margin-top: 2%;
  text-decoration: none;
}

.container-header-account ul {
  list-style: none;
  margin-top: 2%;
  height: 30px;
}

.home-header-account ul li {
  background: white;
}

.home-header-account-sticky ul li {
  background: black;
}

.container-header-account ul li:hover {
  background: grey;
}

.container-header-account ul ul {
  display: none;
}

.container-header-account ul li:hover > ul {
  display: block;
}

.container-header-account ul ul ul {
  margin-left: 170px;
  top: 0px;
  position: absolute;
}

.container-header-account ul ul ul {
  margin-left: 170px;
  top: 0px;
  position: absolute;
}

.autoContainer {
  position: absolute;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: goldenrod;
  background-color: white;
  right: 10px;
  top: 52px;
  width: 350px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  padding: 5px;
  z-index: 1;
}

.option,
span {
  font-size: 16px;
  text-transform: capitalize;
  z-index: 1;
}
/* Moviles */

@media (max-width: 220px) {
  /* Utilizar cuando se acerque un evento importante!  y quitar font-size */

  .icons-footer {
    display: none;
  }

  .home-header-button-event {
    color: white;
    font-size: 14px;
    margin-left: 20px;
  }

  /* @keyframes throb {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
      font-weight: 500;
      color: #AAC130;
    }
    100% {
      transform: scale(1);
  
    }
  } */

  .home-header-button {
    font-family: "Objet Normal Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    color: white;
    text-decoration: none;
    margin-left: 20px;
    font-size: 14px;
  }
  .home-header-premium {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    color: #967c24;
    text-decoration: none;
    margin-left: 20px;
    font-size: 14px;
  }
  .home-header-account {
    width: auto;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: white;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    transition-duration: 250ms;
  }

  .home-header-account ul li {
    background: black;
  }
  .home-logo {
    width: 200px;
    width: calc(120px + 50 * ((100vw - 300px) / (1600 - 300)));
  }
  .home-logo-sticky {
    width: 200px;
    width: calc(120px + 50 * ((100vw - 300px) / (1600 - 300)));
    padding-bottom: 10px;
  }

  .container-header-account {
    padding: 0px 15px 15px 15px;
  }
}
