.carousel-img-event {
    object-fit: none;
    width: 100%;
    max-height: fit-content;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), url('../assets/carousel/banner_2.png');
    background-size: cover;
}

.carousel-img-container {
    width: 100%;
    position: sticky;
    /* max-height: 45vh; */
}

.carousel-img-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
}

.carousel-img-overlay h1{
    font-size: 80px;
}

.carousel-img-overlay {
    font-family: 'Objet Regular';
}

.carousel-img {
    position: relative;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.carousel-info{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 0;
    /* margin: -15px; */
    width: 100%;
    /* height: 50vh; */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.carousel-info h1{
    width: 75%;
    font-family: "Bitner Heavy Italic";
    font-weight: bold;
    font-size: calc(48px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: white;
    z-index: 1;
}

.carousel-info h2{
    width: 75%;
    font-family: "Objet Regular Italic";
    font-weight: bold;
    font-size: calc(48px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: white;
    z-index: 1;
}


/* .carousel-img2 {
    object-fit: none;
    width: 100%;
    max-height: 45vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%), url('../assets/carousel/N2.png');
    background-size: cover;
}

.carousel-img3 {
    object-fit: none;
    width: 100%;
    max-height: 45vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%), url('../assets/carousel/18.png');
    background-size: cover
}

.carousel-img4 {
    object-fit: none;
    width: 100%;
    max-height: 45vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%), url('../assets/carousel/N4.png');
    background-size: cover;
}

.carousel-img5 {
    object-fit: none;
    width: 100%;
    max-height: 45vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%), url('../assets/carousel/N5.png');
    background-size: cover;
}

.carousel-img6 {
    object-fit: none;
    width: 100%;
    max-height: 45vh;
    max-width: 750px;  
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%), url('../assets/carousel/N6.png');
    background-size: cover;
} */

/* Utilizar height: 80vh y margin: -15px 0px 0px 0px cuando se utilice un flayer*/


.banner-info{
    margin-top: 1rem;
    height: fit-content;
}




.evento-reciente {
    height: 450px;
    width: 700px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.25);
    border-radius: 4px;
    transition-duration: 200ms;
    cursor: pointer;
    margin-right: 16px;
    /* &:hover {
        transform: scale(0.98);
        box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
    } */
}

.noticia-imagen {
    max-height: 450px;
    max-width: 500px;
}

.noticia-content {
    display: flex;
    flex-direction: column;
    padding: 8vw;
    background-color: #a1be11;
}

.noticia-text-container {
    display: flex;
    padding-left: 4vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.noticia-text-container h1 {
    font-family: "Bitner Heavy Italic";
    font-weight: bold;
    font-size: calc(32px + 8 * ((100vw - 300px) / (1600 - 300)));
    text-align: center;
    color: black;
    letter-spacing: 0;
}

.noticias-button {
    background-color: black;
    border: none;
    color: #a1be11;
    border-radius: 30px;
    text-align: center;
    align-self: center;
    text-decoration: none;
    padding: 20px;
    width: 245px;
    transition-duration: 0.4s;
    font-family: "Objet Regular";
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}

.noticias-button:hover {
    transform: scale(0.98);
    color: white;
}

.noticia-text-container p {
    font-family: "Objet Normal Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    font-size: calc(20px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: black;
    align-self: center;
}

.noticia-screenshots-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.noticia-screenshots {
    width: 300px;
    object-fit: contain;
}

/* Moviles */

@media (max-width: 920px) {

    .noticia-text-container p {
        text-align: center;
        font-size: calc(15px + 4 * ((100vw - 300px) / (1600 - 300)));
    }

    .carousel-img-overlay h2 {
        font-size: 24px;
    }

    .carousel-img-overlay p {
        font-size: 10px;
    }

    .noticia-screenshots {
        width: fit-content;
        object-fit: contain;
        object-fit: contain;
        max-width: 300px;
        max-height: 70vh;
        margin-bottom: 20px;
    }
    .banner-info{
    margin: 0;
}
.noticia-text-container {
    padding: 0;
    align-items: center;
}
.noticia-text-container h1 {
    font-size: 20px;
    text-align: center;
}
   .noticia-screenshots-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        /* margin-right: 32px;
        padding-bottom: 32px; */
    }
    .noticia-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .carousel-info h1{
        /* height: 20vh; */
        /* padding-left: 2rem; */
        font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 20px;
        color: white;
    }

    .carousel-img {
        height: 100vh;
    }

    .carousel-img-overlay h1{
        font-size: 25px;
        font-family: "Bitner Heavy Italic";
    }

}

/* Ipad */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .carousel-info h1{
        font-size: 30px;
    }

   }