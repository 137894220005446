* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.root {
  border: none;
  border-radius: 25px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.club-register-input-left {
  /* font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif; */
  flex: 1;
  width: 100%;
  height: 50%;
  margin-bottom: 0.1rem;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: center;
  padding-right: 1rem;
}

.club-register-input-right {
  /* font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif; */
  flex: 1;
  width: 100%;
  height: 50%;
  margin-bottom: 0.1rem;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: center;
  padding-left: 1rem;
}

.register-club-label {
  width: 50%;
}

.register-club-intext {
  padding-left: 16px;
  padding-right: 16px;
  display: inline-flex;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
  align-items: center;
  background-color: transparent;
  color: #efeff7;
  border-radius: 8px;
  border-width: 2px;
  /* margin-bottom: 2em; */
}

.register-club-intext::placeholder {
  color: #efeff7;
  /* margin-bottom: 2em; */
}

.Login-club-errorMessage {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: red;
  font-size: calc(6px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 85%;
  align-self: center;
  width: 50%;
}

.container-register-form,
.container-main-login-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: cover;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
}

.club-background {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(3, 30, 27, 0.52) 51.39%, #09221F 95.1%), url("../assets/login/ClubBackground.png");
  background-size: cover;
}

.user-background {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(19, 19, 37, 0.52) 51.39%, #1a1a27 95.1%), url("../assets/login/UserBackground.png");
  background-size: cover;
  
}

.register-form {
  display: grid;
  grid-template-rows: auto max-content;
  height: 100%;
  /* align-items: center; */
  /* background-color: white; */
}

.register-form-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 50px;
  /* align-items: center; */
  align-self: center;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: white; */
}

.login-form-content {
  display: grid;
  grid-template-rows: auto max-content;
  width: 60%;
  height: 100%;
  /* align-items: center; */
  /* background-color: white; */
}

.form-column-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  height: 50%;
}

.register-club-form {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  align-items: center;
  align-self: center;
  justify-content: space-evenly;
}

.register-club-form h2 {
  place-self: self-start;
  font-weight: 700;
  text-align: start;
}
.register-club-form p {
  place-self: self-start;
  margin-top: -30px;
  text-align: start;
}

.registro-boton {
  width: 100%;
  max-width: 280px;
  border: none;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  box-shadow: 1px 1px 10px #000022;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  transition: all 0.2s ease-in-out;
}

.registro-boton:hover {
  transform: scale(1.05);
}

.register-btn {
  display: inline-flex;
  justify-content: space-evenly;
  width: 100%;
  height: 50px;
}

.register-btn button {
  background-color: #fff;
}

.login-btn {
  display: inline-flex;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.login-btn a {
  margin-top: 0.5rem;
  text-align: center;
  color: #efeff7;
  cursor: pointer;
  font-family: "Objet Regular Italic";
}

.register-user-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;
}

.register-club-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-club-input {
  font-family: "Objet Regular Italic";
  /* font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif; */
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
}

.register-club-message {
  display: flex;
  flex-direction: column;
  color: white;
  width: 40%;
  /* height: 50%; */
  margin-top: 2%;
  margin-left: 5%;
  justify-content: space-evenly;
}

.register-club-message div {
  color: white;
  width: 80%;
  display: flex;
  flex-direction: column;
  place-content: space-evenly;
  justify-content: space-evenly;
  gap: 40px;
}

.container-login-form {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  background: linear-gradient(
      180deg,
      #002688 3.65%,
      rgba(36, 61, 125, 0.0338069) 96.74%,
      rgba(37, 61, 122, 0) 100%
    ),
    url("../assets/login/User.png");
  background-size: cover;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
}

.login-userBackground {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(19, 19, 37, 0.52) 51.39%, #1a1a27 95.1%), url("../assets/login/UserBackground.png");
  background-size: cover;
}

.login-clubBackground {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(3, 30, 27, 0.52) 51.39%, #09221F 95.1%), url("../assets/login/ClubBackground.png");
  background-size: cover;
}

.Register-club-title {
  display: flex;
  flex-direction: column;
  text-align: start;
  color: #efeff7;
}

.Register-user-title {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.Register-user-title h2 {
  color: #efeff7;
}

.login-club-title {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 0px 0px 20px 0px;
  text-align: start;
  color: #efeff7;
}

.Register-club-title h2 {
  place-self: self-start;
  text-align: start;
}

.Register-club-title p {
  place-self: self-start;
  text-align: start;
}

.login-form,
.login-form-main {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  height: 100%;
  width: 100%;
}
.login-form-main {
  justify-content: space-evenly;
}

.register-form-1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 100%;
  /* align-items: center; */
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: white;
  /* background-image: url("../assets/login/User.png"); */
  background-size: contain;
  background-position: center;
  height: fit-content;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%), url("../assets/login/User.png")
}

.register-form-2 {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 100%;
  /* align-items: center; */
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: white;
  background-size: cover;
  background-position: center;
  height: fit-content;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%), url("../assets/login/Club.png")
}

.register-message-form {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-evenly;
  align-self: center;
  color: white;
}
.register-message-form h1 {
  width: 100%;
  text-align: center;
  font-size: 36px;
}

.login-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-input {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
}
.login-boton,
.login-boton-1,
.login-boton-2,
.registro-boton {
  width: 100%;
  max-width: 180px;
  border: none;
  border-radius: 8px;
  color: #002688;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  box-shadow: 1px 1px 10px #000022;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  transition: all 0.2s ease-in-out;
  padding: 15px;
  font-weight: bold;
}

.login-boton-2 {
  background-color: white;
  color: black;
  padding: 15px;
}
.login-boton {
  background-color: #DCEC00;
}
.login-boton-1 {
  background-color: #DCEC00;
}

.login-boton:hover {
  transform: scale(1.05);
  background-color: #95C11F;
}

.login-boton-2:hover {
  transform: scale(1.05);
}

.login-boton-1:hover {
  transform: scale(1.05);
  background-color: #95C11F;
}

.redirect-boton {
  width: 50%;
  border: none;
  border-radius: 25px;
  background-color: #16bb60;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  box-shadow: 1px 1px 10px #000022;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  transition: all 0.2s ease-in-out;
  padding: 12px;
  align-items: center;
  margin-bottom: 20px;
}

.redirect-boton:hover {
  transform: scale(1.05);
}

.login-register-message {
  align-self: center;
  cursor: pointer;
  margin-top: 20px;
}

.login-register-message p {
  text-align: center;
  /* color: #007bff; */
}

.login-register-message p a {
  margin-left: 0.5rem;
}

.register-login-message {
  align-self: center;
  cursor: pointer;
  margin-top: 20px;
}

.register-login-message p {
  text-align: center;
  /* color: #007bff; */
}

.register-login-message p a {
  margin-left: 0.5rem;
}

.register-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  height: 10%;
  width: 100%;
}

.register-message a {
  margin-left: 0.5rem;
  color: #DCEC00;
}

.register-message p {
  width: 80%;
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.register-perfil-sex-container {
  display: flex;
  flex-direction: row;
}

.register-perfil-sex {
  justify-content: center;
  align-items: center;
}

.register-perfil-sex input {
  margin-right: 16px;
}

.register-perfil-sex label {
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
  color: #efeff7;
}

.register-perfil-input-container {
  display: flex;
  width: 100%;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  border-style: solid;
  border-color: #efeff7;
  border-width: 2px;
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: center;
}

.register-perfil-input-container-select {
  display: flex;
  width: 100%;
  padding: 0 16px 0 12px;
  height: 40px;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  border-style: solid;
  /* border: solid; */
  border-color: #efeff7;
  font-size: (12px + 4 * ((100vw - 300px) / (1600 - 300)));
  /* margin-bottom: calc(24px + 8 * ((100vw - 300px) / (1600 - 300))); */
  align-items: center;
  background-color: transparent;
  color: #efeff7;
}

.category-perfil-input-container-select {
  display: flex;
  width: 100%;
  padding: 0 16px 0 12px;
  height: 40px;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  border-style: solid;
  border: solid;
  border-color: #efeff7;
  font-size: (12px + 4 * ((100vw - 300px) / (1600 - 300)));
  /* margin-bottom: calc(24px + 8 * ((100vw - 300px) / (1600 - 300))); */
  align-items: center;
}

.category-fc-register {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.label-login-form {
  font-size: 15px;
  font-family: "Objet Regular Italic";
  color: #efeff7;
}

.label-login-form-phone {
  font-size: 15px;
  font-family: "Objet Regular Italic";
  /* color: #efeff7; */
}

.ReactModal__Body--open {
  overflow-y: hidden;
}
.category-fc-information,
.register-perfil-sex-container {
  display: grid;
  row-gap: 10px;
  column-gap: 20px;
  grid-template-columns: repeat(2, calc(50% - 10px));
  flex-direction: row;
  align-self: center;
  width: 100%;
}

.category-fc-input {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 100%;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
}

.register-perfil-input-container {
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  border-style: solid;
  border-color: #efeff7;
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: center;
}

/* Login con Redes Sociales */

.login-social-media {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 0px;
  margin-top: 30px;
  width: 100%;
}

.fb-login-button {
  border-radius: 25px;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(0.27548vw + 12.71074px);
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px);
  margin-top: 29px;
}

.login-google{
  margin-top: 29px;

}

.login-google button {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 25px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  transition: all 0.2s ease-in-out;
}

.login-google button div {
  margin-right: 10px;
  background: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 25px;
}

.login-google button span {
  padding: 10px 10px 10px 0px;
  font-weight: 500;
}

/* .login-google button:hover {
  background-color: rgba(0,0,0,0.02);
  transform: scale(1.05);
    padding: 15px;
    border-radius: 20px;
} */

.fb-login-button .fa {
  margin-right: calc(0.34435vw + 3.38843px);
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.loginZindex1 {
  position: fixed;
  inset: 0px;
  z-index: 1;
}
.go-back-login {
  position: absolute;
  top: 25px;
  left: 25px;
  cursor: pointer;
  width: fit-content;
}

.logo-blanco {
  position: fixed;
  width: 120px;
  height: 120px;
  left: 0px;
  right: 0px;
  margin: auto;
  top: 20px;
}

.tittle-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 850px) {

  .tittle-style {
    display: flex;
    flex-direction: column;
  }

  .logo-blanco {
    display: none;
  }
  .category-fc-information {
    grid-template-columns: 100%;
  }
  .loginZindex1 {
    z-index: 1;
  }
  .container-main-login-form {
    display: grid;
    grid-template-rows: 50% 50%;
  }
  .register-form-2,
  .register-form-1 {
    width: 100%;
    background-position: center;
  }
  .register-form-1 {
    background-image: url("../assets/login/user_mobil.jpg");
  }
  .register-form-2 {
    background-image: url("../assets/login/club_mobil.jpg");
  }
}
@media (max-height: 650px){
  .login-form,
  .register-form-content {
    padding: 20px;
  }
  .Register-user-title, .Register-club-title {
    width: 100%;
  }
  .login-club-title h2,
  .Register-user-title h2, .Register-club-title h2 {
    text-align: center;
    width: 100%;
    font-size: 26px;
    margin: 0;
  }
}
@media (max-height: 650px) and (max-width: 850px) {
  .go-back-login{
    top: 20px;
    left: 20px;
  }
  .Register-user-title, .Register-club-title {
    width: 100%;
  }
  .login-club-title h2,
  .Register-user-title h2, .Register-club-title h2 {
    text-align: center;
    width: 100%;
    font-size: 26px;
    margin: 0;
  }
  .login-form,
  .register-form-content {
    padding: 20px;
  }

  .container-main-login-form {
    flex-direction: column;
  }
  .register-message-form {
    height: fit-content;
  }
  .login-form-main {
    padding: 0;
  }
  .login-btn {
    padding: 10px;
  }
}
@media (max-height: 325px) and (max-width: 850px) {
  .login-btn {
    padding: 5px;
  }
}
@media (max-height: 250px) and (max-width: 850px) {
  .container-main-login-form {
    display: flex;
  }
}
@media (max-width: 640px) {
  .register-club-message {
    display: none;
  }

  .register-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: white; */
    height: 100%;
  }

  .register-message-form h1 {
    font-size: 28px;
    text-align: center;
  }

  .login-form-content {
    width: 100%;
  }
}

@keyframes flipLogin {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 0;
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
.flipLogin {
  backface-visibility: visible !important;
  animation-name: flipLogin;
}

@keyframes flipRegister {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 0;
  }

  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
.flipRegister {
  backface-visibility: visible !important;
  animation-name: flipRegister;
}
