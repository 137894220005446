#container-perfil-club {
  background: #f5f6fa;
  display: flex;
  width: 98%;
  height: 100%;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  align-content: center;
  align-items: center;
  background: white;
  padding-bottom: 15px;
}

#filtros-content-club {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

#filtros-content-club p {
  color: #d9d9d9;
}

.event-container-club {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#filtro-club {
  width: 250px;
}

#court-service-profile {
  padding: 20px 20px 0 20px;
  overflow: auto;
  height: 100%;
}
.perfilClub-court-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 14px;
}
.perfilClub-court-card > .court-info {
  width: calc(100% - 10px);
  overflow: hidden;
}
.perfilClub-court-card > .court-info img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-position: center center;
}
#court-service-profile::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

#court-service-profile::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 4px;
}

#court-service-profile::-webkit-scrollbar-thumb:hover {
  background: #494846;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

#court-service-profile::-webkit-scrollbar-thumb:active {
  background-color: #494846;
}

#court-service-profile::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}
.club-profile-content {
  /* overflow: auto; */
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
  background: white;

  /* display: flex;
  flex-direction: row;
  width: 98%;
  height:100%;
  background-color: white;
  position: unset; */
}

.club-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  background-color: rgba(0, 0, 0, 0.08);
  overflow: auto;
  overflow-y: hidden;
}

.club-right-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow: auto; */
}

.button-back-club {
  width: 100%;
  position: relative;
  bottom: 20px;
  right: 5px;
  height: 0px;
}

.info-perfil-container-club {
  width: 65%;
  max-height: 50%;
}

.info-perfil-container-club h1 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 30px;
  color: black;
  text-align: left;
  /* margin-top: 25px;
    margin-left: 10px; */
}

.info-perfil-container-club h2 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: black;
  text-align: left;
  margin-top: 20px;
  /* margin-top: 25px;
    margin-left: 10px; */
}

.info-perfil-container-club h3 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #949494;
  margin-right: 1rem;
  text-align: center;
  /* margin-top: 25px;
    margin-left: 10px; */
}

.profile-week-calendar {
  display: flex;
  flex-direction: column;
  background: #f5f6fa;
  height: 200px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.container-categorias-club {
  background: #f5f6fa;
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background: white;
  padding-bottom: 15px;
}

.club-profile-menu {
  display: flex;
  flex-direction: row;
  transition: 0.5;
  gap: 24px;
  /* margin-top: 1rem;
  margin-left: 1rem; */
}

.club-profile-menu div {
  cursor: pointer;
  color: #2085b7;
  min-width: 65px;
  text-align: center;
}

.club-profile-menu div::after {
  content: "";
  display: block;
  height: 3px;
  background-color: #2085b7;
  transform: scale(0, 1);
  transform-origin: center;
  transition: transform 0.25s;
}

.club-profile-menu div:hover::after,
.club-profile-menu .menu-item-active::after {
  transform: scale(1, 1);
}

.menu-item-active {
  height: 27px;
  font-weight: bold;
  transition: 0.5;
  pointer-events: none;
}

.barra {
  margin-left: 1rem;
  background: #aac130;
  width: 100px;
  height: 10px;
}

.club-profile-menu a {
  margin-right: 5%;
}

.map-container {
  position: relative;
}

.name-perfil-club {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 22px;
  color: black;
  text-align: center;
  /* margin-top: 25px;
    margin-left: 10px; */
}

.name-perfil-club h2 {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: black;
  text-align: center;
  margin-top: 20px;
}

.location-perfil-club {
  display: flex;
  flex-direction: row;
  place-content: center;
}

.about-perfil-club {
  /* display: flex;
  flex-direction: column;
  align-self: flex-end; */
  margin-left: -15%;
  overflow: auto;
  width: 125%;
  max-height: 51%;
  text-align: center;
}

.about-perfil-club p {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  /* color: #ffffff; */
  color: black;
  margin-left: 7%;
  word-break: break-word;
  text-align: justify;
}

/* About scrollbar styles */

.about-perfil-club::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

.about-perfil-club::-webkit-scrollbar-thumb {
  background: #7c7c7c;
  border-radius: 4px;
}

.about-perfil-club::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.about-perfil-club::-webkit-scrollbar-thumb:active {
  background-color: #494846;
}

.about-perfil-club::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.boton-perfil-club {
  display: none;
}
.Modal-form-courts {
  width: 60%;
  border: none;
  margin: 5% auto;
  top: auto;
  bottom: auto;
  max-width: 1080px;
  overflow: auto;

  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
}
.Modal-form-courts-Image {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.ReactModal__Overlay--after-open {
  overflow: auto;
}

/* Arrendar cancha texto */

.rent-court {
  margin: 0;
}

.arrow-rent-court {
  cursor: pointer;
  width: 28px;
  height: 28px;
  font-size: 30px;
}

@media (max-width: 1440px) {
  .rent-court {
    display: none;
  }

  .arrow-rent-court {
    display: none;
  }

  .about-perfil-club {
    max-height: 42%;
  }

  .name-perfil-club {
    font-size: 18px;
  }

  .info-perfil-container-club h3 {
    font-size: 10px;
  }

  .name-perfil-club h2 {
    font-size: 14px;
  }

  .about-perfil-club p {
    font-size: 12px;
  }
}

@media (max-width: 920px) {
  .perfilClub-court-card > .court-info {
    gap: 20px;
  }
  .info-perfil-container-club h3 {
    font-size: 12px;
  }

  .name-perfil-club {
    font-size: 18px;
  }
  .Modal-form-courts-Image {
    width: calc(100% + 40px);
    position: absolute;
    height: 160px;
    transform: translate(-20px, -20px);
  }
  .Modal-form-courts {
    width: 90%;
    margin: 0;
    position: unset;
    inset: 25% 40px 40px 0%;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    outline: none;
    width: 100%;
    height: 100vh;
    padding: 20px;
  }
  .club-profile-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 9px 4px 17px rgba(156, 156, 203, 0.1);
    border-radius: 4px;
    justify-content: stretch;
    width: 100%;
    overflow: auto;
  }
  .club-left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 62%; */
    background-color: rgba(0, 0, 0, 0.08);
    overflow: unset;
  }

  .club-right-content {
    width: 100%;
    /* height: 38%; */
  }

  .about-perfil-club {
    /* max-height: 35%; */
    font-size: 14px;
  }

  .club-profile-menu {
    /* align-items: center;
    align-self: center; */
    width: 95%;
    place-content: center;
    margin-left: 5px;
    font-size: 13px;
  }
  .boton-perfil-club {
    display: flex;
    place-content: center;
  }

  #court-service-profile {
    height: 100%;
    margin-left: 0;
  }
  .perfilClub-court-card {
    grid-template-columns: 100%;
    height: 350px;
    margin-bottom: 0;
  }
  .profile-week-calendar {
    display: none;
  }
  .info-perfil-container-club {
    margin-top: 10px;
    margin-right: 0;
  }
}

@media (max-width: 1440px) {
  .profile-week-calendar {
    height: 135px;
  }
}
