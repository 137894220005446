.container-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 80%; */
}

.container-upload h1 {
    margin-top: 10px;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.container-selectOption {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    border-bottom: 1px solid #cfd6e7;
    padding-bottom: 0.5rem;
    width: 100%;
}

.img {
    width: 400px;
    height: 200px;
    /* height: 10%; */
    /* max-height: 285px; */
    object-fit: cover;
    border-radius: 6px;
    margin-top: 0;
}

#file-browser-input {
    width: 400px;
    height: 200px;
    top: 32px;
    left: 20px;
    position: absolute;
    color: transparent;
    opacity: 0;
    cursor: pointer;
}

.container-form-fotos {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.input-img {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-self: center;
    width: 200px;
    border-radius: 10px;
    border: none;
    background-color: #DCEC00;
    padding: 5px;
    color: #2085B7;
    line-height: 15px;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 14px;
}

.input-upload {
    display: none;
}

.input-label {
    cursor: pointer;
    font-size: 14px;
    padding-top: 8px;
    font-weight: 700;
    color: white;
    display: inline-block;
    background-color: #12a526;
}

.input-img+label {
    cursor: pointer;
    /* "hand" cursor */
}

.input-img+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.select-event {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.select-event h3 {
    font-size: 20px;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
}

.img-size {
    width: 400px;
    height: 200px;
    border-radius: 6px;
}

.label-upload {
    position: relative;
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-top: 0rem;
    padding-bottom: 0px;
}

@media (max-width: 920px) {

    .label-upload {
        font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    }

    .input-img {
        width: 150px;
    }

    #file-browser-input {
        width: 320px;
        height: 180px;
    }

    .img {
        max-width: 320px;
        height: 180px;
    }

    .img-size {
        max-width: 320px;
        height: 180px;
    }

    .container-upload {
        height: 100%;
        overflow: auto;
    }

    .container-form-fotos {
        display: flex;
        align-items: center;
        width: 100%;
    }



}