.edit-court-club-content{
    width: 100%;
    min-height: 300px;
    height: 100%;
    display: flex;
    flex-direction:column;
}
.edit-court-club-content-welcome{
    width: 75%;
    min-height: 300px;
    height: 100%;
    display: flex;
    flex-direction:column;
}

.edit-newcourt-club-content{
    width: 100%;
    /* height: 100%; */
    /* text-align: -webkit-center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* overflow: auto; */
    
}
.court-column{
    overflow: auto;
    padding-right: 30px;
    padding-left: 30px;
    margin-bottom: 4%;
}
.court-column::-webkit-scrollbar{
    width: 5px;
    height: 8px;
  }
  
  .court-column::-webkit-scrollbar-thumb{
    background: #C4C4C4;
    border-radius: 4px;
  }
  
  .service-column::-webkit-scrollbar-thumb:hover{
    background: #494846;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  
  .court-column::-webkit-scrollbar-thumb:active{
    background-color: #494846;
  }
  
  .court-column::-webkit-scrollbar-track{
    background: #e1e1e1;
    border-radius: 4px;
  }

  .edit-form-columns {
    top: 25px;
    right: 55px;
    display: flex;
    justify-content: flex-end;
  }

.edit-court-title{
    display: flex;
    flex-direction: column;
    width: 65%;
}

.btn-court{
    flex-basis: auto;
}

.container-form-columns-cancha{
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 100%;   
}

.add-new-court-btn {
    border: none;
    border-radius: 6.4px;
    text-align: center;
    text-decoration: none;
    padding: 7.5px 26px;
    /* width: 239.2px; */
    transition-duration: 200ms;
    cursor: pointer;
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    color: #002688;

}
.service-club-bg-btn{
    display: none;
}
.add-new-court-btn:hover {
    transform: scale(0.98);
    box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
}
@media screen and (max-width: 920px){
    .edit-form-columns {
        top: 70px;
        right: 55px;
      }
    .court-column{
        margin-top: 45px;
    }
}
@media screen and (max-width: 480px){
    .court-column{
        margin-top: 8px;
    }
    .edit-form-columns {
        bottom: 8px;
        top: unset;
        right: 0;
        left: 0;
        margin: auto;
        width: 90%;
        z-index: 1;
        position: fixed;
        justify-content: center;
    }
    .add-new-court-btn{
        width: 100%;
        padding: 15px;
    }
    .court-column{
        margin-bottom: 40px;
        padding-bottom: 80px;
    }
    .service-club-bg-btn{
        display: block;
    }
    .service-club-btn {
        position: fixed;
        padding-bottom: 6px;
        bottom: 40px;
        width: 90%;
        left: 0;
        right: 0;
        margin: auto;
      }
    .edit-court-title{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
    }

    .edit-court-title h2 {
        font-size: 24px;
    }

    #disponibility-actions{
        flex-direction: column;
    }

    .court-info h3{
        margin-left: 8px;
    }

    .btn-court{
        width: 100%;
        height: 50px;
    }


}


