.Politic h1{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(30px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
}

.Politic p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #6F767A;
    margin-top: 32px;
}

.informacion h2{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
    
}

.informacion p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #6F767A;
    margin-top: 32px;
}

.uso h2{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
}

.uso p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #6F767A;
    margin-top: 32px;
}

.cookies h2{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
}

.cookies p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #6F767A;
    margin-top: 32px;
}

.enlaces h2{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
    
}

.enlaces p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #6F767A;
    margin-top: 32px;
}

.control h2{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
}

.control p{
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #6F767A;
    margin-top: 32px;
}