
.login-content {
    display: flex;
    flex: 1;
    padding-left: 8vw;
    padding-right: 8vw;
}

.login-text-container {
    display: flex;
    flex:1;
    padding-right: 4vw;
    flex-direction: column;
    justify-content: center;
}

.login-text-container h1 {
    font-family: "Bitner Heavy Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    font-weight: bold;
    font-size: calc(28px + 8 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
    margin: -3px;
}


.login-text-container p {
    font-family: "Objet Bold Italic";
    /* font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif; */
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #6F767A;
    margin-top: 32px;
}

.login-screenshots-container {
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 32px;
    padding-bottom: 32px;
}

.login-screenshots {
    width: 300px;
    object-fit: contain;
}

/* Moviles */
@media (max-width: 920px){
    .login-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .login-text-container {
        display: flex;
        flex:1;
        padding-right: 4vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .login-screenshots {
        width: 50vw;
    }

    .login-screenshots-container {
        margin-right: 0px;
    }
    
}