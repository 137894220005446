.edit-service-club-content{
  width: 100%;
  min-height: 300px;
  height: 100%;
  display: flex;
  flex-direction:column;
  /* margin-top: 1%; */
}

.edit-service-club-content-welcome{
  width: 75%;
  min-height: 300px;
  height: 100%;
  display: flex;
  flex-direction:column;
}
.service-column{
  overflow: auto;
    padding-right: 30px;
    padding-left: 30px;
    margin-bottom: 4%;
}

.service-column::-webkit-scrollbar{
  width: 10px;
  height: 8px;
}

.service-column::-webkit-scrollbar-thumb{
  background: black;
  border-radius: 4px;
}

.service-column::-webkit-scrollbar-thumb:hover{
  background: #494846;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.service-column::-webkit-scrollbar-thumb:active{
  background-color: #494846;
}

.service-column::-webkit-scrollbar-track{
  background: #e1e1e1;
  border-radius: 4px;
}

.Service-span-2{
  grid-column: span 2;
}
.container-form-columns-services{
    display: flex;
    flex-direction: column;
}

.form-column-service{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    margin-top: 1rem;
  }

  .form-column-service-description{
    grid-column: span 2;
    display: grid;
    grid-template-rows: auto 1fr 16px;
    font-family: "Objet Regular Italic";
    flex: 1 1;
    width: 100%;
    flex-direction: column;
    font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
    align-items: flex-start;
}
.service-event-intext-area,
.service-club-error {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 10px;
  display: flex;
  border: 1.5px solid #202020;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  margin-left: 0;
}

.select-event-intext-service
  {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 10px;
    display: flex;
    border: 2px solid #95C11F;
    border-radius: 4px;
    font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    width: 100%;
    margin-left: 0;
}
.select-event-intext-service:focus-visible,
.select-event-intext-service:focus{
  border: 2px solid #a0cf1f;
  outline: none;
}

  .form-column-service-select{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    align-items: flex-start;
    margin-top: 1rem;
  }

  .service-event-intext {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    padding: 15px 10px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
    box-sizing: border-box;
    margin-bottom: 1vw;
    height: 46%;
    width: 95%;
    margin-left: 0;
  }

  .post-register-content textarea{
    height: 100px;
    resize: none;
    width: 100%;
  }
  
.service-event-label {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-top: 0rem;
    width: 100%;
    padding-bottom: 0px;
    text-align: flex-start;
  }

  .service-select{
      display: flex;
      justify-content: center;
  }

  .column-img-service{
    background-color: black;
    min-height: 100px;
  }

  .img-service{
    width: 100%;
    height: 100%;
    background-color: white;
  }

  .edit-services-club-content{
    width: 75%;
    min-height: 300px;
    height: 100%;
    display: flex;
    flex-direction:column;
}

.btn-service{
  width: 35%;
  height: 50px;
}

.edit-service-title{
  display: flex;
  flex-direction: column;
  width: 65%;
}

.add-new-services-btn {
  background-color: #000022; 
  border: none;
  color: white;
  border-radius: 6.4px;
  text-align: center;
  text-decoration: none;
  min-width: 100%;
  padding: 7.5px;
  /* width: 239.2px; */
  transition-duration: 200ms;
  cursor: pointer;
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
}

.add-new-services-btn:hover {
  transform: scale(0.98);
  box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
}

.service-club-error {

  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  padding: 15px 10px;
  display: flex;
  border-radius: 4px;
  font-size: calc(8px + 4 * ((100vw - 300px) / (1600 - 300)));
  box-sizing: border-box;
  height: 46%;
  width: 95%;
  margin-left: 0;
  border: 1px solid red;

}

.service-club-errorMessage {
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: red;
  font-size: calc(6px + 4 * ((100vw - 300px) / (1600 - 300)));
  width: 85%;
}


.service-column ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.service-column ::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.service-column ::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.service-column ::-webkit-scrollbar-thumb:active {
  background-color: black;
}

.service-column ::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

  @media screen and (max-width: 480px){
  
    .container-form-columns-services{
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    .select-event-intext-service {
      font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
      height: 60%;  
      width: 100%;
    }

    .service-event-label {
      /* font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif; */
      font-style: normal;
      font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
      margin-top: 0rem;
      width: 100%;
      padding-bottom: 0px;
      text-align: flex-start;
    }

    .service-event-intext {
      font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
      height: 100%;
      width: 100%;
    }



    .form-column-service{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      margin-top: 1rem;
    }

    .form-column-service-select{
      display: flex;
      flex-direction: column;
      width: 96%;
      height: 100%;
      align-items: flex-start;
      margin-top: 1rem;
      margin-left: 8px;
    }

    .select-event-intext{
      height: 50%;
      width: 91%;
      margin-left: 0rem;
      margin-bottom: 0rem;
    }

    .btn-service{
      width: 100%;
      height: 50px;
    }

    .edit-service-title{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .edit-service-title h2 {
      font-size: 24px;
    }


    .service-club-errorMessage {
      font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
      color: red;
      font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
      width: 85%;
    }
    .service-club-error{
      font-size: calc(11px + 4 * ((100vw - 300px) / (1600 - 300)));
    }
  
}

