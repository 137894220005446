.LargeCard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.25);
  border-radius: 4px;
  transition-duration: 200ms;
  cursor: pointer;
  margin-bottom: 16px;
  height: 40px;
  padding: 0 16px;
}
.LargeCard-header {
  background-color: rgba(241, 241, 255, 0.9);
}
.LargeCard-items {
  padding: 16px;
  height: auto;
}
.Inscritos-header th {
  font-family: "Segoe UI";
  color: #999999;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.Inscritos-body td,
.Inscritos-body th {
  font-family: "Segoe UI";
  border: none;
}
.inscritos-filtros-container {
  display: flex;
  flex-direction: row;
  font-family: "Segoe UI";
  padding: 12px 0;
  flex-wrap: wrap;
  gap: 0 12px;
}
.inscritos-main-buttons{
  position: absolute;
}
.inscritos-filtros {
  width: 232px;
  align-items: center;
  border-color: #efeff7;
  border-radius: 4px;
  border-style: solid;
  box-shadow: 9px 4px 17px rgb(156 156 203 / 10%);
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-family: "Segoe UI";
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
}
.inscritos-search-input {
  display: flex-end;
  border: none;
}
.inscritos-input-container {
  display: flex;
  border-radius: 8px;
  font-family: "Segoe UI";
  border-style: normal;
  border-color: #efeff7;
  font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: center;
  box-shadow: 9px 4px 17px rgba(156, 156, 203, 0.1);
}
.LargeCard-items:hover {
  transform: scale(0.98);
  box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
}
.Inscritos-content-header {
  height: 100%;
}
.Inscritos-content, .Inscritos-content-header{
    display: grid;
    grid-template-columns: 51px repeat( 4 ,calc((100% - 51px) / 4));
    width: 100%;
}

.Inscritos-content-dual, .Inscritos-content-header-dual{
  display: grid;
  grid-template-columns: 51px repeat( 5 ,calc((100% - 51px) / 5));
  width: 100%;
}

.inscritos-for-saving, .inscritos-for-saving-header{
  display: grid;
  grid-template-columns: 51px repeat( 5 ,calc((100% - 51px) / 5));
  width: 100%;
}

.inscritos-for-saving-dual, .inscritos-for-saving-header-dual{
  display: grid;
  grid-template-columns: 51px repeat( 6 ,calc((100% - 51px) / 6));
  width: 100%;
}

.actionBotton{
  display: flex;
  flex-direction: row;
}

/* .saveBotton{
  content: '';
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #AAC130;
  padding: 8px 24px;
  color: #fff;
  cursor: pointer;
  margin-right: 1rem;
}
.deleteBotton{
  content: '';
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #d02822;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  margin-right: 1rem;
} */

.Inscritos-content,
.Inscritos-content-header {
  display: grid;
  grid-template-columns: 51px repeat(4, calc((100% - 51px) / 4));
  width: 100%;
}
.Inscritos-paymenttype {
  font-weight: 600;
}

.TitleEvent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  position: relative;
}

.SelectUserContainer {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  padding-inline: 10px;
}

.config-perfil-button-inscritos {
  width: 260px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
  margin-top: 70px;
  margin-right: 20px;
  border: none;
  background-color: #000022;
  color: #fff;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: "right";
  cursor: pointer;
  transition-duration: 250ms;
}

.Importcontainer{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.toolsButtons{
  display:none;
}

.labelImport{
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  color: #2085b7;
  cursor: pointer;
  margin:0
}

.detallebotones {
  margin: 0px;
  color: #2085b7;
  font-size: 16px;
  width: 100%;
  text-align: center;
}

.for-save{
  display: flex;
  width: 100%;
  background-color:#AAC130 ;
  color: white;
  justify-content: center;
  border-radius: 20px;
}

.for-error{
  display: flex;
  width: 100%;
  background-color:#d02822 ;
  color: white;
  justify-content: center;
  border-radius: 20px;
}

.external-user-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 46px;
  padding-inline: 16px;
}

.externalForm-1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.externalForm-2 {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10;
}

.newPlayerForm {
  width: 100%;
  align-self: center;
}

.detalle-oldPlayer {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  gap: 20px;
  place-self: end;
  grid-row: span 2;
  flex-wrap: wrap;
  justify-content: center;
  width: 50%;
  align-self: center;
  padding-top: 30px;
}

.button-back-event {
  display: none;
}
.externalSingleUserform {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 20px;
}

.addPlayerDisabled {
  opacity: 0.5;
  transform: none;
}

.addPlayer {
  opacity: 1;
}

.addPlayer:hover {
  background: #95c11f;
  opacity: 0.6;
}

.addPlayerDisabled:hover {
  opacity: 0.5;
  transform: none;
}


@media (max-width: 920px) {
  .external-user-inputs {
    grid-template-columns: 1fr;
  }
  .SelectUserContainer {
    padding-inline: 0;
  }
  .inscritos-filtros-container {
    padding: 12px;
  }
  .inscritos-main-buttons{
    position: inherit;
  }
  .Inscritos-header {
    font-size: 12px;
  }
  .inscription-category {
    justify-self: center;
    font-size: 13px;
  }
  .Inscritos-paymenttype {
    font-weight: 600;
    justify-self: center;
    font-size: 13px;
  }
  .inscription-name {
    font-size: 13px;
  }
  .registration-title {
    font-size: 2rem;
  }
  .view-inscritos {
    overflow: auto;
    height: 65vh;
  }
  
  .externalForm-2 {
    width: 100%;
  }

  .newPlayerForm {
    width: 100%;
  }
  .detalle-oldPlayer {
    width: 100%;
  }
  .button-back-event {
    display: block;
    top: 0;
    z-index: 0;
    left: 10;
  }
}
@media (max-width: 420px) {
  .externalSingleUserform {
  padding-inline: 0px;
  }
  .ReactModal__Content .TitleEvent{
    padding-inline: 15px;
  }
  .ReactModal__Content .TitleEvent svg{
    transform: translateX(-30px);
  }
}
