.home-recintos-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #42425D;
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
}

.home-recintos-content {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    width: 95%;
    margin-bottom: 25px;
}

.recinto-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #2D2D4B;
    margin-bottom: 5%;
}

.home-recinto-parent-normal {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 300px;
    border: 1px solid #2D2D4B;
    cursor: pointer;
    /* importante */
    overflow: hidden;
    transition-duration: 2s;
}

.home-mapa-container {
    width: 10%;
    height: 100%;
    background-color: teal;
    transition-duration: 2s;
}

.home-recinto {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: black;
    background-image: url('../assets/tenis-bg.jpg');
    background-position: center;
    background-size: cover;
    opacity: 1;
    transition-duration: 0.8s;
    text-align: center;
}

.home-recinto h5 {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: white;
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
    transition-delay: 0.5s
}

.home-recinto img {
    position: absolute;
    max-height: 75px;
    max-width: 200px;
    top: 15%;
    left: 27.5%;
    transition-duration: 1s;
}

.home-detalle-recinto {
    position: absolute;
    color: white;
    font-size: large;
    font-weight: bold;
    top: 50%;
    left: 25%;
    transition-duration: 1s;
}

.home-recinto-parent:hover .home-recinto, .home-recinto-parent2:hover .home-recinto, .home-recinto-parent-normal:hover .home-recinto {
    transform: scale(1.2);
    transition-duration: 1s;
}
.recinto-container:hover .home-recinto h5 {
    font-size: 16px;
    transition-duration: 1s;
}
.recinto-container:hover .home-recinto img {
    max-height: 75px;
    max-width: 200px;
}
/* 
.recinto-container:hover .recinto-container{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
} */

.recinto-container:hover .home-recinto-parent-normal {
    width: 50%;
    transition-duration: 1s;
}

.recinto-container:hover .home-mapa-container {
    width: 50%;
    transition-duration: 1s;
}

.recinto-container:hover .home-recinto img {
    display: block;
    position: absolute;
    top: 20%;
    left: 12.5%;
    /* opacity: 0; */
    transition-duration: 1s;
}

.recinto-container:hover .home-detalle-recinto {
    display: block;
    position: absolute;
    top: 50%;
    left: 10%;
    transition-duration: 1s;
}

/* .home-recinto::before {
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
} */

@media screen and (max-width: 960px) {
    .recinto-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: hotpink;
        margin-bottom: 15%;
    }
    .home-recinto-parent-normal {
        width: 100%;
    }
    .home-mapa-container {
        width: 100%;
        height: 150px;
    }
    .recinto-container:hover .home-recinto-parent-normal {
        width: 100%;
        transition-duration: 1s;
    }
    .recinto-container:hover .home-mapa-container {
        width: 100%;
        transition-duration: 1s;
    }
    .recinto-container:hover .home-recinto img {
        display: block;
        position: absolute;
        top: 20%;
        left: 25%;
        /* opacity: 0; */
        transition-duration: 1s;
    }
    .recinto-container:hover .home-detalle-recinto {
        display: block;
        position: absolute;
        top: 50%;
        left: 25%;
        transition-duration: 1s;
    }
}

.home-recinto-parent {
    position: relative;
    width: 100%;
    height: 250px;
    display: inline-block;
    border: 1px solid #2D2D4B;
    cursor: pointer;
    /* importante */
    overflow: hidden;
    /* margin-top: -75px; */
    object-fit: contain;
}

.home-recinto-parent2 {
    position: relative;
    width: 100%;
    height: 250px;
    display: inline-block;
    border: 1px solid #2D2D4B;
    cursor: pointer;
    /* importante */
    overflow: hidden;
}