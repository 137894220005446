@font-face {
  font-family: "Airbnb Cereal App";
  font-weight: bold;
  src: url("./assets/fonts/AirbnbCereal-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Airbnb Cereal App";
  font-weight: normal;
  src: url("./assets/fonts/AirbnbCereal-Book.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Bitner Heavy Italic";
  font-weight: bold;
  src: url("./assets/fonts/Bitner-W00-Heavy-Italic.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Objet Regular Italic";
  font-weight: normal;
  src: url("./assets/fonts/Objet-RegularItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Objet Normal Italic";
  font-weight: normal;
  src: url("./assets/fonts/Objet-RegularItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Objet Regular";
  font-weight: normal;
  src: url("./assets/fonts/Objet-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Objet Bold Italic";
  font-weight: bold;
  src: url("./assets/fonts/Objet-ExtraBoldItalic.ttf");
  font-display: swap;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
