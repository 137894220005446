
.details-myrents {
    margin-bottom: 1rem;
}

.details-myrents h1 {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
}

.title-myrents {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
}

.canchas-filtro {
    align-items: center;
    justify-content: space-between;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
    margin-bottom: 15px;
    max-width: 500px;
}

.canchas-filtros {
    align-items: center;
    border-color: #efeff7;
    border-radius: 4px;
    border-style: solid;
    box-shadow: 9px 4px 17px rgba(156, 156, 203, 0.1);
    align-items: center;
    justify-content: space-between;
    width: 28%;
    height: 100%;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
    padding: 12px 0;
}

.container-lessor {
    width: 100%;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.25);
    border-radius: 4px;
    transition-duration: 200ms;
    cursor: pointer;
    margin-bottom: 16px;
    height: 40px;
    padding: 0 16px;
    background-color: rgba(241, 241, 255, 0.9);
}

.lessor-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.lessor-header {
    font-weight: 600;
    height: 100%;
    display: flex;
    align-items: center;
}

.details-content {
    display: flex;
    height: 300px;
    /* width: 350px; */
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 2rem;
    /* background-color: #dedee9; */
    border:3px solid #efeff7;
    border-radius: 5px;
  }

  .details-content p {
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  