.land-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    padding-top: 2vw;
    padding-left: 16vw;
    padding-right: 16vw;
}

.land-text-container {
    display: flex;
    flex:1;
    padding-right: 4vw;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.land-content a {
    width: 100%;
}

.land-text-container h1 {
    font-family: "Bitner Heavy Italic";
    font-weight: bold;
    font-size: calc(36px + 20 * ((100vw - 300px) / (1600 - 300)));
    color: #333;
    letter-spacing: 0;
    margin: 0;
}

.land-text-container p {
    font-family: "Objet Bold Italic";
    font-size: calc(14px + 4 * ((100vw - 300px) / (1600 - 300)));
    align-content: center;
    color: #6F767A;
    letter-spacing: 0;
    margin-top: 32px;
}

.google-trademark-text {
    font-family: "Objet Bold Italic";
    font-weight: 400;
    font-size: calc(12px + 4 * ((100vw - 300px) / (1600 - 300)));
    color: #999;
    letter-spacing: 0;
    margin-top: 8px;
}

.land-text-container a {
    align-self: flex-start;
}

.land-text-container img {
    height: 80px;
}

.land-screenshots-container {
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.land-screenshots {
    width: 500px;
    object-fit: contain;
}


/* Móviles */
@media (max-width: 920px){
    .land-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding-left: 8vw;
        padding-right: 8vw;
    }

    .land-text-container {
        display: flex;
        flex:1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .land-screenshots-container {
        margin-top: 50px;
        display: flex;
        align-items: center;
    }

    .land-screenshots {
        width: 90vw;
    }
}