.videos-web-title {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 100%;
    align-items: center;
}

.videos-web-title h3{
    font-family: "Objet Normal Italic";
    font-weight: bold;
}

.container{
    position: sticky;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

.container .card{
    position: relative;
    width: 330px;
    height: 430px;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: 20px;
    text-align: center;
    transition: all 0.25s;
    background-color: white;
    cursor: pointer;
}

.container .card:hover{
    transform: translateY(-15px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}

.container .card .img{
    width: 330px;
    height: 300px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%)
}
.img-card{
    width: 330px;
    height: 300px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
    object-fit: cover;
}

.titulo-card-video{
    font-family: "Objet Normal Italic";
    font-weight: bold;
    font-size: 25px;
    color: #333;
    font-weight: 600;
    margin-top: 10px;
}

.parrafo-video{
    padding: 0 1rem;
    font-size: 16px;
    font-weight: 300;
    font-family: "Objet Regular Italic";
    color: #6F767A;
    margin-top: 15px;
}

