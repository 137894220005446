.court {
  margin-bottom: 5%;
}

.court-card {
  width: 100%;
  height: 250px;
  /* background-color: gray; */
  display: flex;
  border-radius: 5px;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.court-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.court-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.court-info img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.court-info-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  max-height: 65%;
}
.court-type{
  font-weight: bold;
  width: fit-content;
}
.court-info h3 {
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  /* color: #ffffff; */
  color: black;
  display: flex;
  align-items: flex-start;
  margin-left: 7%;
}
.court-info-column p {
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  /* color: #ffffff; */
  color: black;
  margin-left: 7%;
  overflow-y: auto;
}

.court-info-column p::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.court-info-column p::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.court-info-column p::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.court-info-column p::-webkit-scrollbar-thumb:active {
  background-color: black;
}

.service-info-column p::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.court-info h5 {
  font-style: normal;
  font-weight: bold;
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  /* color: #ffffff; */
  color: black;
  place-self: flex-start;
  margin-left: 7%;
}

.fa-courts {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
}
.fa-courts button{
  border: none;
}

#disponibility-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

#disponibility-actions div:nth-of-type(1) {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px;
}

.court-disponibility {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  flex-direction: row;
  overflow: auto;
  gap: 10px;
  max-width: 100%;
}
.court-disponibility-small{
  font-size: 60px;
}
.court-days-contenido {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: calc((1rem + 1vw) - 0.2em);
  width: fit-content;
  height: fit-content;
  margin: auto;
}
.new-court-days-contenido {
  position: absolute;
  top: 18px;
  left: 0;
  bottom: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
}


.court-days-width {
  width: 90%;
  max-width: 50px;
  background: #f9d237;
  position: relative;
  display: inline-block;
  margin: 1%;
  vertical-align: top;
}
.court-days-width:before, .conteiner-day:before {
  content: '';
  display: block;
}
.court-days:before, .conteiner-day:before {
  padding-top: 100%;
}
.court-days {
  font-weight: 600;
  border-radius: 100%;
  background-color: #dbdbe9;
  overflow: hidden;
}
.container-form-courts {
  display: flex;
  flex-direction: column;
  width: 100%;
  place-content: center;
}

.rent-court-label {
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: calc(20px + 4 * ((100vw - 300px) / (1600 - 300)));
  margin-top: 0rem;
  width: 100%;
  padding-bottom: 0px;
  text-align: center;
}
.rent-court-label-left {
  text-align: left;
}

.form-column-rent-court {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 37.7%;
  justify-content: space-between;
  /* align-items: center; */
  margin-top: 1rem;
  /* margin-left: 1.5rem; */
  margin-bottom: 2rem;
}

.rent-court-input {
  font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
  display: flex;
  flex: 1;
  width: 100%;
  /* height: 50%; */
  margin-bottom: 0.1rem;
  flex-direction: column;
  font-size: calc(10px + 4 * ((100vw - 300px) / (1600 - 300)));
  align-items: center;
}

.rent-court-duration {
  background-color: rgb(170, 193, 48);
  margin-right: 43px;
  padding: 4px 12px 8px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}
.rent-court-number {
  font-size: 2.1rem;
  font-weight: 700;
}

.rent-court-min {
  text-transform: uppercase;
  font-size: 12px;
}

.rent-court-hour {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.rent-court-hour-item {
  background-color: #fff;
  margin-top: 12px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
  padding: 4px 12px 8px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  width: 23%;
  cursor: pointer;
}

.rent-court-hour-item span {
  font-size: 20px;
}

/* #rent-court{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px;
} */


@media screen and (max-width: 480px) {
  .court-card {
    width: 100%;
    height: 100%;
    /* background-color: gray; */
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .court-info {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .court-info-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    max-height: 200px;
  }

  .court-info img {
    object-fit: cover;
    width: 100%;
    min-height: 200px;
    border-radius: 5px;
  }

  .court-info-column p {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    color: black;
    align-items: flex-start;
    overflow: auto;
    height: 30%;
    width: 95%;
    margin-left: 10px;
    text-align: justify;
  }

  .court-info h3 {
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: black;
    display: flex;
    align-items: flex-start;
    margin-top: 5%;
    margin-left: 10px;
  }

  .court-info h5 {
    font-style: normal;
    font-weight: bold;
    font-family: 'Airbnb Cereal App', Arial, Helvetica, sans-serif;
    color: black;
    place-self: flex-start;
    margin-left: 10px;
  }

  .fa-courts {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .court-disponibility {
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
  }

  .form-column-rent-court{
    height: 100%;
  }

 
}
