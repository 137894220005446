* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.button-back-map {
  margin-left: 20px;
  margin-top: 36px;
  cursor: pointer;
  font-size: 40px;
  color: #2085b7;
}

.info-map-content {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.info-map-row-1 {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #f9f9f9;
}

.info-map-title {
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
  margin-top: 20px;
  /* height: 17%; */
}

.info-map-title h1 {
  font-size: 36px;
  font-weight: bold;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  margin-left: 35px;
}

.info-map-search-type {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-map-search-location {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-map-search-location-tittle {
  display: flex;
  flex-direction: row;
}

.info-map-location {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
}

.info-map-location h2 {
  font-size: 30px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-map-events {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
}

.info-map-events::-webkit-scrollbar {
  width: 0px;
}

.info-map-events::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 4px;
}

.info-map-events::-webkit-scrollbar-thumb:hover {
  background: #494846;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.info-map-events::-webkit-scrollbar-thumb:active {
  background-color: #494846;
}

.info-map-events::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.info-map-description-events {
  position: sticky;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  transition-duration: 200ms;
  cursor: pointer;
}

.map-events-cards {
  background-color: #f6f6f6;
  border-radius: 5px;
  transition-duration: 200ms;
  cursor: pointer;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.info-map-description-events:hover,
.map-events-cards:hover {
  box-shadow: 0px 6px 13px 0px #0b0b0b29, 5px 5px 15px 5px rgb(0 0 0 / 0%);
  -webkit-box-shadow: 0px 6px 13px 0px #0b0b0b29, 5px 5px 15px 5px rgb(0 0 0 / 0%);
}
.info-map-description-clubes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50%;
  margin-bottom: 10px;
  border: solid 1px grey;
  border-radius: 5px;
  transition-duration: 200ms;
  cursor: pointer;
}

.info-map-description-clubes:hover {
  transform: scale(0.98);
  box-shadow: 0px 4px 20px rgba(180, 172, 172, 0.8);
}

.info-map-img {
  width: 50%;
  object-fit: cover;
  position: relative;
}

.info-map-img img {
  width: 90%;
  height: 132px;
  border-radius: 5px 0px 0px 5px;
  object-fit: cover;
}

.info-map-text {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  text-align: justify;
  flex-direction: column;
  position: relative;
}

.info-map-text p {
  overflow-y: auto;
}

/* Eventos  */

.info-event-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
  position: relative;
}

.go-card-event {
  font-family: "Airbnb Cereal App";
  color: white;
  margin-top: 8px;
  height: 28px;
  text-align: center;
  display: flex;
  align-items: center;
  right: 5px;
}

.go-card-event:hover {
  background-color: #424141;
}

.info-event-title h4 {
  margin-top: 8px;
  font-size: 15px;
  font-weight: normal;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
}

.info-event-title h3 {
  font-size: 17px;
  font-weight: bold;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  margin-top: 10px;
}

.info-event-status h4 {
  font-size: 15px;
  font-weight: normal;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  opacity: 0.5;
}

.info-event-status h3 {
  font-size: 17px;
  font-weight: bold;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
}

.info-event-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  /* margin-top: 15px; */
  margin-right: 3px;
}

.info-event-price h3 {
  font-size: 15px;
  font-weight: normal;
}

.info-event-price h4 {
  font-size: 15px;
  font-weight: bold;
  margin-right: 6px;
}

/* Clubes */

.info-club-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-club-title h3 {
  margin-top: 6px;
  font-size: 17px;
  font-weight: bold;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
}

.info-club-phone {
  overflow: auto;
  height: 50%;
}

.info-club-phone h4 {
  font-size: 15px;
  font-weight: normal;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  opacity: 0.5;
  margin-top: 6px;
}

.info-club-phone h3 {
  margin-top: 6px;
  font-size: 15px;
  font-weight: normal;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
}

.info-map-regions {
  display: flex;
  justify-content: space-between;
  width: 120%;
}

.info-map-regions h3 {
  font-size: 22px;
  font-weight: normal;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  color: #2085b7;
}

/* Canchas */

.info-court-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-court-title h4 {
  margin-top: 6px;
  font-size: 15px;
  font-weight: normal;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
}

.info-court-price {
  flex-direction: row;
  justify-content: space-between;
  margin-right: 3px;
}

.info-court-price h3 {
  font-size: 17px;
  font-weight: bold;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  margin-top: 10px;
}

.info-court-price h4 {
  font-size: 15px;
  font-weight: bold;
}

/* Botones e Iconos */

.icon-quit {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
}

.icon-quit:hover {
  color: rgb(110, 108, 108);
}

.detail-card-event {
  font-family: "Airbnb Cereal App";
  font-weight: bold;
  display: inline;
  border: solid 1px;
  background-color: white;
  position: absolute;
  margin-top: 8px;
  left: 5px;
  top: 0px;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0 0 8px -2px #000;
  padding-right: 8px;
  padding-left: 4px;
}

.detail-card-event:hover {
  background-color: #eeeeee;
}

.button-detail {
  font-family: "Airbnb Cereal App";
  font-weight: bold;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 8px -2px #000;
  margin-top: 5px;
  padding: 4px 10px 4px 4px;
  position: absolute;
  top: 10px;
  left: 15px;
  transition: 0ms;
}

.button-detail:hover {
  background-color: #c2bebe;
}

.button-go {
  font-family: "Airbnb Cereal App";
  font-weight: bold;
  background-color: black;
  color: white;
  border: solid 1px grey;
  border-radius: 4px;
  margin-top: 5px;
  padding: 5px 10px 5px 5px;
}

.button-go:hover {
  background-color: rgb(110, 106, 106);
}
.info-btn {
  width: 30%;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: 18px;
  height: 40px;
  cursor: pointer;
  transition: all 0.5s;
  margin: 0px 10px 0px 10px;
  border: 1px solid #dcec00;
}

.info-btn-country {
  width: 18%;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: 18px;
  height: 40px;
  cursor: pointer;
  transition: all 0.5s;
  margin: 0px 10px 0px 10px;
  border: 1px solid #dcec00;
}

.info-btn-regions {
  width: 23%;
  border-radius: 8px;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  font-size: 18px;
  height: 40px;
  cursor: pointer;
  transition: all 0.5s;
  margin: 0px 10px 0px 10px;
  white-space: nowrap;
  background-color: white;
  min-width: 160px;
  border: 1px solid #dcec00;
  color: #2085b7;
  font-weight: bolder;
}

.info-btn-regions:focus {
  background-color: #dcec00;
  color: #2085b7;
  font-weight: bolder;
}

.info-btn-regions:target {
  background-color: black;
  color: #2085b7;
}

.info-btn-default {
  background-color: white;
}

.info-map-row-content {
  position: relative;
}
.translate-y-15-negative {
  transform: translateY(-60px);
}
.info-map-row-2-title {
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 10px;
  border: solid 1px gray;
  border-radius: 4px;
  background-color: white;
  color: black;
  padding: 5px 20px 10px 25px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  width: fit-content;
}

.info-map-row-2-title h2 {
  font-size: 18px;
  font-weight: bold;
  font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
  position: relative;
  top: 18px;
  right: 5px;
}

.info-logo {
  width: 70px;
  height: 40px;
  margin-left: 5px;
  object-fit: cover;
}

.icon-custom-torneo {
  position: absolute;
  left: -24;
  top: -24;
  background-color: black;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 2px solid grey;
  cursor: pointer;
  transition: 0.25s cubic-bezier(0.485, 1.65, 0.545, 0.835) 0s;
  /* transform-origin: 15px 60px;  */
  /* z-index: 3000; */
}

.icon-custom-club {
  position: absolute;
  left: -24;
  top: -24;
  background-color: black;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 2px solid grey;
  cursor: pointer;
  transition: 0.25s cubic-bezier(0.485, 1.65, 0.545, 0.835) 0s;
}

.icon-custom-torneo:hover {
  background-color: gray;
  z-index: 2;
  transform: scale(2, 2);
}

.icon-custom-club:hover {
  background-color: gray;
  z-index: 1;
  transform: scale(2, 2);
}

.Modal-form-maps {
  position: absolute;
  inset: 50% 50% -45%;
  border: none;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  transform: translate(-50%, -50%);
  width: 80%;
}

.detalle-row-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.detalle-row-2 img {
  vertical-align: middle;
  border-style: none;
  width: 100%;
  height: 95vh;
  object-fit: cover;
}

.responsive-card {
  left: 18px;
  position: absolute;
  min-width: 353px;
  margin-top: 2rem;
}

.responsive-card-media {
  height: 140px;
}

@media (max-width: 838px) {
  .info-btn {
    font-size: 15px;
  }

  .info-btn-regions {
    font-size: 15px;
  }

  .detail-card-event {
    margin-top: 4px;
    padding-top: 4px;
    font-size: 12px;
  }

  .go-card-event {
    margin-top: 4px;
  }

  .info-event-title h4 {
    font-size: 12px;
  }

  .info-event-status h3 {
    font-size: 11px;
    margin-right: 5px;
  }

  .info-event-status h4 {
    font-size: 10px;
    margin-right: 5px;
  }

  .info-event-price h3 {
    font-size: 10px;
  }

  .info-event-price h4 {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .button-back-map {
    font-size: 30px;
  }

  .info-map-description-events {
    height: 125px;
  }

  .go-card-event {
    font-size: 12px;
    height: 22px;
  }

  .detail-card-event {
    font-size: 12px;
  }
  .info-btn-country {
    height: 30px;
    font-size: 12px;
  }

  .info-btn-regions {
    height: 30px;
    font-size: 12px;
    min-width: 120px;
  }
  .info-map-content {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }

  .info-map-row-1 {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }

  .info-map-title {
    margin-top: 5px;
  }

  .info-map-title h1 {
    font-size: 20px;
    margin-bottom: -5px;
    margin-left: 0px;
  }

  .info-map-search h3 {
    font-size: 13px;
  }

  .info-map-search-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .info-map-search {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .info-btn {
    width: 20%;
    border-radius: 8px;
    background-color: white;
    font-family: "Airbnb Cereal App", Arial, Helvetica, sans-serif;
    font-size: 12px;
    height: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .info-map-location h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .info-map-events {
    height: 150px;
  }

  .info-map-img img {
    height: 123px;
  }

  .info-map-text {
    display: flex;
    flex-direction: column;
    text-align: start;
    position: relative;
  }

  /* Torneos  */

  .info-event-status h3 {
    font-size: 12px;
  }

  .info-event-title h4 {
    font-size: 9px;
    margin-top: 8px;
  }

  .info-event-title h3 {
    font-size: 14px;
    margin-top: 20px;
  }

  .info-event-status h4 {
    font-size: 9px;
  }

  .info-event-price {
    margin-top: 5px;
    margin-right: 15px;
  }

  .info-event-price h3 {
    font-size: 10px;
  }

  .info-event-price h4 {
    font-size: 10px;
  }

  /* Clubes  */

  .info-club-phone h3 {
    font-size: 10px;
  }

  .info-map-description-clubes {
    height: 100%;
  }

  .info-club-title h4 {
    font-size: 9px;
    margin-top: 8px;
  }

  .info-club-title h3 {
    font-size: 12px;
    margin-top: 8px;
  }

  .info-club-phone {
    overflow: hidden;
    flex-direction: column;
    /* margin-top: 20px; */
  }

  .info-club-phone h4 {
    font-size: 10px;
    margin-top: 18px;
  }

  .detalle-row-2 {
    display: none;
  }

  .Modal-form-maps {
    position: absolute;
    inset: 50% 50% -45%;
    border: none;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
  }

  /* Canchas */

  .info-court-title h4 {
    font-size: 12px;
  }

  .info-court-price h3 {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .info-court-price h4 {
    font-size: 12px;
    margin-top: 15px;
  }

  /* .detail{
      align-self: center;
  } */

  .makeStyles-root-1 {
    min-width: 250px !important;
  }

  .makeStyles-media-2 {
    height: 100px !important;
  }

  .button-go {
    display: none;
  }
}

/* Modal */
@media (max-width: 900px) {
  .map-events-cards {
    transition-duration: 200ms;
    cursor: pointer;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
