.container-imagenes {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.img-container {
    width: 25%;
    height: 300px;
    object-fit: cover;
}

@media (max-width: 920px){
    .container-imagenes {
        display: none;
    }
}